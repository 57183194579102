import { useQuery, useMutation } from "@apollo/client";
import {
  GET_LEASES,
  UPDATE_COMMERCIAL_LEASE_STAGE,
  CREATE_COMMERCIAL_LEASE,
} from "queries";
import { Box, Stack, Typography } from "@mui/material";
import Loading from "components/shared/Loading";
import MultipleContainers from "./MultipleContainers";
import { LEASE_STAGES, PERMISSIONS } from "utils/constants";
import { useSnackbar } from "notistack";
import { useState, useEffect, useContext } from "react";
import CommercialLeaseForm from "./CommerciaLeaseForm";
import Button from "@mui/material/Button";
import LeaseFilters from "./LeaseFilters";
import { useTheme } from "@mui/material/styles";
import { useQueryParams, withDefault, ArrayParam } from "use-query-params";
import AddIcon from "@mui/icons-material/Add";
import CurrentUserContext from "contexts/CurrentUserContext";

function LeaseList() {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [filters, setFilters] = useQueryParams({
    property: withDefault(ArrayParam, []),
    assignee: withDefault(ArrayParam, []),
  });
  const { data, refetch, loading } = useQuery(GET_LEASES, {
    fetchPolicy: "network-only",
    variables: { filters },
  });
  const [leases, setLeases] = useState([]);
  const [items, setItems] = useState([]);
  const { can } = useContext(CurrentUserContext.Context);

  const [updateLeaseStage] = useMutation(UPDATE_COMMERCIAL_LEASE_STAGE);
  const [createLease] = useMutation(CREATE_COMMERCIAL_LEASE);
  useEffect(
    _ => {
      if (data?.commercialLeases) setLeases(data?.commercialLeases);
    },
    [data],
  );
  useEffect(
    _ => {
      const leasesToItems = _ => {
        const Interested = leases
          .filter(({ Stage }) => Stage === LEASE_STAGES.Interested)
          .map(({ commercialLeaseId }) => commercialLeaseId);
        const Touring = leases
          .filter(({ Stage }) => Stage === LEASE_STAGES.Touring)
          .map(({ commercialLeaseId }) => commercialLeaseId);
        const Proposal = leases
          .filter(({ Stage }) => Stage === LEASE_STAGES.Proposal)
          .map(({ commercialLeaseId }) => commercialLeaseId);
        const Cancelled = leases
          .filter(({ Stage }) => [LEASE_STAGES.Cancelled].includes(Stage))
          .map(({ commercialLeaseId }) => commercialLeaseId);
        const Lost = leases
          .filter(({ Stage }) => [LEASE_STAGES.Lost].includes(Stage))
          .map(({ commercialLeaseId }) => commercialLeaseId);
        const Leased = leases
          .filter(({ Stage }) => [LEASE_STAGES.Leased].includes(Stage))
          .map(({ commercialLeaseId }) => commercialLeaseId);
        const items = {
          Interested,
          Touring,
          Proposal,
          Leased,
          Lost,
          Cancelled,
        };

        return items;
      };
      setItems(leasesToItems(leases));
    },
    [leases],
  );
  const isBroker = can([PERMISSIONS.broker]);
  async function handleDragEnd(Stage, commercialLeaseId) {
    const lease = leases.find(
      ({ commercialLeaseId: id }) => commercialLeaseId === id,
    );
    if (lease.Stage === Stage) return;

    try {
      await updateLeaseStage({
        variables: { commercialLeaseId, Stage },
      });
      setLeases(leases => {
        return leases.map(lease => {
          if (lease.commercialLeaseId === commercialLeaseId) {
            return { ...lease, Stage: Stage };
          } else return lease;
        });
      });
      enqueueSnackbar(`${lease.LeaseName} successfully moved`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Couldn't update Lease stage", error);

      enqueueSnackbar(
        `Encountered an issue updating Lease, please try again or contact support if you believe this to be in error`,
        {
          variant: "error",
        },
      );
    }
  }
  const handleAddNew = async (formData, onSuccess) => {
    const {
      units,
      propertyId,
      TenantDbaName,
      Stage,
      SignDate,
      SecurityDeposit,
      RecoveryPerArea,
      GrossRentAnnualPerArea,
      NAICSCode,
      MoveIn,
      MiscellaneousPerArea,
      LsType,
      SpaceType,
      LeaseTo,
      LeaseName,
      LeaseFrom,
      notes,
      assignee,
    } = formData;

    function toUnix(date) {
      return date.valueOf();
    }

    try {
      await createLease({
        variables: {
          createCommercialLeaseInput: {
            units,
            propertyId,
            TenantDbaName,
            Stage,
            NAICSCode,
            GrossRentAnnualPerArea: Number(GrossRentAnnualPerArea),
            SecurityDeposit: Number(SecurityDeposit),
            RecoveryPerArea: RecoveryPerArea ? Number(RecoveryPerArea) : null,
            MiscellaneousPerArea: MiscellaneousPerArea
              ? Number(MiscellaneousPerArea)
              : null,
            LsType: `${SpaceType} ${LsType}`,
            LeaseName,
            MoveIn: MoveIn ? toUnix(MoveIn) : null,
            SignDate: SignDate ? toUnix(SignDate) : null,
            LeaseFrom: LeaseFrom ? toUnix(LeaseFrom) : null,
            LeaseTo: LeaseTo ? toUnix(LeaseTo) : null,
            notes: JSON.stringify(notes),
            assignee: assignee || null,
          },
        },
      });

      enqueueSnackbar(`Lease successfully added`, {
        variant: "success",
      });
      refetch();
      onSuccess();
    } catch (error) {
      console.error("Couldn't save Lease", error);

      enqueueSnackbar(
        `Encountered an issue adding Lease, please try again or contact support if you believe this to be in error`,
        {
          variant: "error",
        },
      );
    }
  };
  return (
    <Box
      sx={{
        p: 2,
        pt: 4,
        backgroundColor: "#fff",
        minHeight: "calc(100vh - 7rem)",
      }}
    >
      <Stack direction="row" sx={{ pb: "1rem", ml: "0.5rem", width: "143rem" }}>
        <Typography
          variant="h3"
          sx={{ fontSize: "24px", textAlign: "left", fontWeight: 500 }}
        >
          Leasing Pipeline
        </Typography>

        <Box sx={{ ml: "auto" }}>
          {isBroker && (
            <CommercialLeaseForm
              Action={({ setOpen }) => (
                <Button
                  variant="contained"
                  sx={{
                    m: "0",
                    ml: "auto",
                    width: "16rem",
                    textTransform: "capitalize",
                  }}
                  onClick={_ => setOpen(true)}
                  data-cy="addButton"
                  startIcon={<AddIcon />}
                >
                  Add Prospect
                </Button>
              )}
              title="Add Prospect"
              onSubmit={handleAddNew}
            />
          )}
        </Box>
      </Stack>
      <Stack direction="row" sx={{ mt: "0.5rem", mb: "1rem", ml: "0.8rem" }}>
        <Typography
          variant="h5"
          sx={{
            fontSize: "18px",
            textAlign: "left",
            color: theme.palette.neutral.main,
            mt: "0.5rem",
          }}
        >
          Filters:
        </Typography>
        <LeaseFilters filters={filters} setFilters={setFilters} />
      </Stack>
      {loading ? (
        <Loading sx={{ mt: "15rem" }} />
      ) : (
        <MultipleContainers
          items={items}
          totalItems={leases}
          onDragEnd={handleDragEnd}
          handle
        />
      )}
    </Box>
  );
}

export default LeaseList;
