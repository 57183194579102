import { gql } from "@apollo/client";

export default gql`
  query GetLeaseComments($entity: CommentEntity!) {
    comments(entity: $entity) {
      text
      leaseCommentId
      createdAt
      updatedAt
      createdBy {
        firstName
        lastName
        userId
      }
    }
  }
`;
