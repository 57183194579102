import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import { NumericFormat } from "react-number-format";

const LEASE_TYPES = ["NNN", "Gross"];
const SPACE_TYPES = ["Office", "Retail", "Industrial"];

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, value, ...other } = props;

  return (
    <NumericFormat
      {...other}
      value={value ? Number(value).toFixed(2) : value}
      getInputRef={ref}
      decimalScale={2}
      onValueChange={values => {
        onChange(values.value);
      }}
      // displayType={"text"}
      thousandSeparator
      valueIsNumericString
      // renderText={text => <b>{text}</b>}
    />
  );
});

function LeaseTerms({ watch, control, errors }) {
  const watchLsType = watch("LsType");
  const watchSpaceType = watch("SpaceType");

  const isGrossType = watchLsType && "Gross" === watchLsType;

  return (
    <>
      <Controller
        control={control}
        name="LsType"
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <InputLabel id="LsType" required>
              Lease Type
            </InputLabel>
            <Select
              error={errors.LsType}
              labelId="LsType"
              label="Lease Type"
              required
              {...field}
            >
              {LEASE_TYPES.map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {watchLsType && (
        <Controller
          control={control}
          name="SpaceType"
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl>
              <InputLabel id="SpaceType" required>
                Space Type
              </InputLabel>
              <Select
                error={errors.spaceType}
                labelId="SpaceType"
                label="Space Type"
                required
                {...field}
              >
                {SPACE_TYPES.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      )}
      {watchSpaceType && (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Controller
              name="GrossRentAnnualPerArea"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label="Annual Rent Per SQFT"
                  {...field}
                  error={errors.Rent}
                  required
                  data-cy="GrossRentAnnualPerArea"
                  sx={{ width: "48%" }}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              name="SecurityDeposit"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label="Security Deposit"
                  {...field}
                  error={errors.SecurityDeposit}
                  required
                  data-cy="SecurityDeposit"
                  sx={{ width: "48%" }}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
          {!isGrossType && (
            <Stack direction="row" justifyContent="space-between">
              <Controller
                name="RecoveryPerArea"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label="Annual Recovery Per SQFT"
                    {...field}
                    error={errors.Recov}
                    required
                    data-cy="RecoveryPerArea"
                    sx={{ width: "48%" }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="MiscellaneousPerArea"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label="Annual Miscellaneous Per SQFT"
                    {...field}
                    error={errors.Misc}
                    required
                    data-cy="MiscellaneousPerArea"
                    sx={{ width: "48%" }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-between">
            <Controller
              name={"LeaseFrom"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  label="Lease From"
                  renderInput={params => <TextField {...params} />}
                  {...field}
                  required
                  sx={{ width: "48%" }}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                />
              )}
            />
            <Controller
              name={"LeaseTo"}
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Lease To"
                  renderInput={params => <TextField {...params} />}
                  {...field}
                  sx={{ width: "48%" }}
                />
              )}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Controller
              name={"SignDate"}
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Sign Date"
                  renderInput={params => <TextField {...params} />}
                  {...field}
                  sx={{ width: "48%" }}
                />
              )}
            />
            <Controller
              name={"MoveIn"}
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Move In"
                  renderInput={params => <TextField {...params} />}
                  {...field}
                  sx={{ width: "48%" }}
                />
              )}
            />
          </Stack>
        </>
      )}
    </>
  );
}

export default LeaseTerms;
