import Paper from "@mui/material/Paper";
import { useQuery } from "@apollo/client";
import { GET_COMMERCIAL_LEASE } from "queries";
import { Box, Stack, Typography } from "@mui/material";
import Loading from "components/shared/Loading";
import { useParams } from "react-router-dom";
import { ReadOnlyTextEditior } from "components/shared/TextEditor";
import CommercialLeaseForm from "./CommerciaLeaseForm";
import dayjs from "dayjs";
import { NAICS_OPTIONS } from "utils/constants";
import LeaseStage from "./LeaseStage";
import { styled } from "@mui/material/styles";
import { buildAddress } from "utils/formatters/address";
import StoreIcon from "@mui/icons-material/Store";
import WorkIcon from "@mui/icons-material/Work";
import PropertyIcon from "components/shared/icons/PropertyIcon";
import ArticleIcon from "@mui/icons-material/Article";
import { NavLink as Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { UPDATE_COMMERCIAL_LEASE } from "queries";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import LeaseComments from "./LeaseComments";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { getDisplayName } from "utils/formatters/user";
import InfoIcon from "@mui/icons-material/Info";
import CurrentUserContext from "contexts/CurrentUserContext";
import { PERMISSIONS } from "utils/constants";
import { useContext } from "react";
const boxWidth = "75.4rem";
const borderColor = "#90A0B7";

const NavLink = styled(Link)`
  text-decoration: none;
`;
function LeaseDate({ unix }) {
  const date = unix && dayjs(unix).format("MM/DD/YYYY");
  return <>{date || "None"}</>;
}
const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  margin-left: 2rem;
  line-height: 1.2;
`;
const GridContainer = styled(Box)`
  border: 1px solid ${borderColor};
  border-radius: 4px;
  padding: 1.2rem 4rem 1.3rem;
  max-width: ${boxWidth};
`;
const GridTitleWrapper = styled(Box)`
  display: flex;
  margin-bottom: 1.3rem;
`;
const GridAttributes = styled(Box)``;
const AttributeRow = styled(Box)`
  display: flex;
  border-top: 1px solid ${borderColor};
  color: #4c5862;
  font-size: 12px;
  height: 3.9rem;
  align-items: center;
`;
const AttributeLabel = styled(Box)`
  width: 18rem;
  font-weight: 500;
  text-transform: uppercase;
`;
const AttributeValue = styled(Box)`
  max-width: 40rem;
`;

function ViewLease({ lease, refetch }) {
  const {
    property,
    LeaseName,
    notes,
    units,
    TenantDbaName,
    commercialLeaseId,
    assignee,
  } = lease;
  const address = property ? buildAddress(property) : "";
  const { enqueueSnackbar } = useSnackbar();
  const [updateLease] = useMutation(UPDATE_COMMERCIAL_LEASE);
  const { can } = useContext(CurrentUserContext.Context);
  const isBroker = can([PERMISSIONS.broker]);
  const isAdmin = can([PERMISSIONS.admin]);
  const submitEdit = async (formData, onSuccess) => {
    const {
      units,
      propertyId,
      TenantDbaName,
      Stage,
      SignDate,
      SecurityDeposit,
      RecoveryPerArea,
      GrossRentAnnualPerArea,
      NAICSCode,
      MoveIn,
      MiscellaneousPerArea,
      LsType,
      SpaceType,
      LeaseTo,
      LeaseName,
      LeaseFrom,
      notes,
      assignee,
    } = formData;

    function toUnix(date) {
      return date.valueOf();
    }

    try {
      // return;
      await updateLease({
        variables: {
          updateCommercialLeaseInput: {
            id: commercialLeaseId,
            units,
            propertyId,
            TenantDbaName,
            Stage,
            NAICSCode,
            GrossRentAnnualPerArea: Number(GrossRentAnnualPerArea),
            SecurityDeposit: Number(SecurityDeposit),
            RecoveryPerArea: RecoveryPerArea ? Number(RecoveryPerArea) : null,
            MiscellaneousPerArea: MiscellaneousPerArea
              ? Number(MiscellaneousPerArea)
              : null,
            LsType: `${SpaceType} ${LsType}`,
            LeaseName,
            MoveIn: MoveIn ? toUnix(MoveIn) : null,
            SignDate: SignDate ? toUnix(SignDate) : null,
            LeaseFrom: LeaseFrom ? toUnix(LeaseFrom) : null,
            LeaseTo: LeaseTo ? toUnix(LeaseTo) : null,
            notes: JSON.stringify(notes),
            assignee,
          },
        },
      });

      enqueueSnackbar(`Lease successfully updated`, {
        variant: "success",
      });
      refetch();
      onSuccess();
    } catch (error) {
      console.error("Couldn't update Lease", error);

      enqueueSnackbar(
        `Encountered an issue updating Lease, please try again or contact support if you believe this to be in error`,
        {
          variant: "error",
        },
      );
    }
  };
  return (
    <Stack direction="row">
      <Stack direction="column" sx={{ width: "100%" }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            mt: "1rem",
            width: "73rem",
            ml: "2rem",
            fontSize: "1.4rem",
          }}
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <NavLink
            to="/leasing"
            sx={{
              fontSize: "1.4rem",
            }}
          >
            Leasing Pipeline
          </NavLink>
          <Typography
            color="text.primary"
            sx={{
              fontSize: "1.4rem",
            }}
          >
            {LeaseName}
          </Typography>
        </Breadcrumbs>
        <Typography
          variant="h3"
          sx={{
            fontSize: "24px",
            textAlign: "left",
            ml: "2rem",
            mt: "1.5rem",
            mb: "1.5rem",
            fontWeight: 500,
          }}
        >
          Lease Details
        </Typography>
        <Stack direction="row">
          <Paper
            elevation={2}
            sx={{
              p: "3rem",
              pb: "1rem",
              mt: "1rem",
              borderRadius: "8px",
              width: "73rem",
              ml: "2rem",
              mb: "2rem",
            }}
          >
            <Stack direction="row">
              <Typography
                variant="h2"
                sx={{
                  fontSize: "22px",
                  fontWeight: 500,
                  mb: "1rem",
                  mr: "3rem",
                }}
              >
                {LeaseName}
              </Typography>

              {isBroker && (
                <CommercialLeaseForm
                  values={lease}
                  title="Edit Prospect"
                  Action={({ setOpen }) => (
                    <IconButton
                      onClick={_ => setOpen(true)}
                      data-cy="editDocument"
                      sx={{ ml: "auto", width: "24px", height: "24px" }}
                    >
                      <EditIcon color="neutral" />
                    </IconButton>
                  )}
                  onSubmit={submitEdit}
                />
              )}
            </Stack>
            <GridContainer
              sx={{
                pr: 0,
                mt: "3rem",
              }}
              data-cy={`section-company`}
            >
              <GridTitleWrapper>
                <InfoIcon />
                <Title variant="h6">Status</Title>
              </GridTitleWrapper>
              <GridAttributes>
                <AttributeRow key={"Status"} data-cy={`attribute-Status`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Stage
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`Status`}
                  >
                    <LeaseStage stage={lease.Stage} />
                  </AttributeValue>
                </AttributeRow>
                {isAdmin && (
                  <AttributeRow
                    key={"brokerGroup"}
                    data-cy={`attribute-brokerGroup`}
                  >
                    <AttributeLabel data-cy={`attributeLabel`}>
                      Broker Group
                    </AttributeLabel>
                    <AttributeValue
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      data-cy={`brokerGroup`}
                    >
                      {lease?.brokerGroup?.displayName || "None"}
                    </AttributeValue>
                  </AttributeRow>
                )}
                <AttributeRow key={"Assignee"} data-cy={`attribute-Assignee`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Assignee
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`Assignee`}
                  >
                    {assignee ? getDisplayName(assignee) : "Unassigned"}
                  </AttributeValue>
                </AttributeRow>
              </GridAttributes>
            </GridContainer>
            <GridContainer
              sx={{
                pr: 0,
                mt: "3rem",
              }}
              data-cy={`section-company`}
            >
              <GridTitleWrapper>
                <WorkIcon />
                <Title variant="h6">Company</Title>
              </GridTitleWrapper>
              <GridAttributes>
                <AttributeRow key={"LeaseName"} data-cy={`attribute-LeaseName`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Lease Legal Name
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`LeaseName`}
                  >
                    {LeaseName}
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow
                  key={"TenantDbaName"}
                  data-cy={`attribute-TenantDbaName`}
                >
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Tenant DBA Name
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`TenantDbaName`}
                  >
                    {TenantDbaName || "None"}
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow key={"NAICSCode"} data-cy={`attribute-NAICSCode`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Industry
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`NAICSCode`}
                  >
                    {
                      NAICS_OPTIONS.find(({ id }) => id === lease.NAICSCode)
                        .displayName
                    }
                  </AttributeValue>
                </AttributeRow>
              </GridAttributes>
            </GridContainer>
            {property && (
              <GridContainer
                sx={{
                  pr: 0,
                  mt: "3rem",
                }}
                data-cy={`section-company`}
              >
                <GridTitleWrapper>
                  <PropertyIcon />
                  <Title variant="h6">Property</Title>
                </GridTitleWrapper>
                <GridAttributes>
                  <AttributeRow
                    key={"PropertyName"}
                    data-cy={`attribute-PropertyName`}
                  >
                    <AttributeLabel data-cy={`attributeLabel`}>
                      Name
                    </AttributeLabel>
                    <AttributeValue
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      data-cy={`PropertyName`}
                    >
                      {property && (
                        <NavLink
                          to={`/property/features/details?property=${property.propertyId}`}
                          data-cy="topNavItem"
                          sx={{
                            fontWeight: 600,
                            ":hover": { textDecoration: "underline" },
                          }}
                        >
                          {property.PropertyName}
                        </NavLink>
                      )}
                    </AttributeValue>
                  </AttributeRow>
                  <AttributeRow key={"Address"} data-cy={`attribute-Address`}>
                    <AttributeLabel data-cy={`attributeLabel`}>
                      Address
                    </AttributeLabel>
                    <AttributeValue
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      data-cy={`Address`}
                    >
                      {address}
                    </AttributeValue>
                  </AttributeRow>
                  <AttributeRow
                    key={"TotalSQFT"}
                    data-cy={`attribute-TotalSQFT`}
                  >
                    <AttributeLabel data-cy={`attributeLabel`}>
                      Total SQFT
                    </AttributeLabel>
                    <AttributeValue
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      data-cy={`TotalSQFT`}
                    >
                      {property &&
                        `${property.TotalSQFT?.toLocaleString("en")} SQFT`}
                    </AttributeValue>
                  </AttributeRow>
                  <AttributeRow key={"Manager"} data-cy={`attribute-Manager`}>
                    <AttributeLabel data-cy={`attributeLabel`}>
                      Property Manager
                    </AttributeLabel>
                    <AttributeValue
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      data-cy={`Manager`}
                    >
                      {property?.PropertyManager}
                    </AttributeValue>
                  </AttributeRow>
                </GridAttributes>
              </GridContainer>
            )}
            {property && units.length > 0 && (
              <GridContainer
                sx={{
                  pr: 0,
                  mt: "3rem",
                }}
                data-cy={`section-company`}
              >
                <GridTitleWrapper>
                  <StoreIcon />
                  <Title variant="h6">Unit{units?.length > 1 && "s"}</Title>
                </GridTitleWrapper>
                <GridAttributes>
                  {units.map(({ UnitCode, SQFT, Building, Floor, Status }) => (
                    <AttributeRow key={UnitCode} data-cy={`Unit-${UnitCode}`}>
                      <AttributeLabel
                        data-cy={`attributeLabel`}
                        sx={{ width: "10rem" }}
                      >
                        {UnitCode}
                      </AttributeLabel>
                      <AttributeValue
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          maxWidth: "55rem",
                        }}
                        data-cy={`Unit`}
                      >
                        <Stack direction="row">
                          <Box sx={{ mr: "1rem", width: "10rem" }}>
                            Bldg {Building || "N/A"}
                          </Box>

                          <Box sx={{ mr: "1rem", width: "8rem" }}>
                            Floor {Floor || "N/A"}
                          </Box>

                          <Box sx={{ width: "11rem", mr: "1rem" }}>
                            {SQFT.toLocaleString() || "N/A"} SQFT
                          </Box>

                          <Box sx={{ width: "20rem" }}>
                            <b>{Status}</b>
                          </Box>
                        </Stack>
                      </AttributeValue>
                    </AttributeRow>
                  ))}
                </GridAttributes>
              </GridContainer>
            )}
            <GridContainer
              sx={{
                pr: 0,
                mt: "3rem",
              }}
              data-cy={`section-company`}
            >
              <GridTitleWrapper>
                <ArticleIcon />
                <Title variant="h6">Lease</Title>
              </GridTitleWrapper>
              <GridAttributes>
                <AttributeRow key={"LsType"} data-cy={`attribute-LsType`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Lease Type
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`LsType`}
                  >
                    {lease.LsType.split(" ")[1]}
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow key={"SpaceType"} data-cy={`attribute-SpaceType`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Space Type
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`SpaceType`}
                  >
                    {lease.LsType.split(" ")[0]}
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow
                  key={"GrossRentAnnualPerArea"}
                  data-cy={`attribute-GrossRentAnnualPerArea`}
                >
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Annual Rent Per SQFT
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`GrossRentAnnualPerArea`}
                  >
                    {lease.GrossRentAnnualPerArea.toLocaleString(undefined, {
                      style: "currency",
                      currency: "USD",
                    })}
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow
                  key={"SecurityDeposit"}
                  data-cy={`attribute-SecurityDeposit`}
                >
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Security Deposit
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`SecurityDeposit`}
                  >
                    {lease.SecurityDeposit.toLocaleString(undefined, {
                      style: "currency",
                      currency: "USD",
                    })}
                  </AttributeValue>
                </AttributeRow>
                {lease.RecoveryPerArea && (
                  <AttributeRow
                    key={"RecoveryPerArea"}
                    data-cy={`attribute-RecoveryPerArea`}
                  >
                    <AttributeLabel data-cy={`attributeLabel`}>
                      Recovery Per SQFT
                    </AttributeLabel>
                    <AttributeValue
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      data-cy={`RecoveryPerArea`}
                    >
                      {lease.RecoveryPerArea.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </AttributeValue>
                  </AttributeRow>
                )}
                {lease.MiscellaneousPerArea && (
                  <AttributeRow
                    key={"MiscellaneousPerArea"}
                    data-cy={`attribute-MiscellaneousPerArea`}
                  >
                    <AttributeLabel data-cy={`attributeLabel`}>
                      Miscellaneous Per SQFT
                    </AttributeLabel>
                    <AttributeValue
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      data-cy={`MiscellaneousPerArea`}
                    >
                      {lease.MiscellaneousPerArea.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </AttributeValue>
                  </AttributeRow>
                )}
                <AttributeRow key={"LeaseFrom"} data-cy={`attribute-LeaseFrom`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Lease From
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`LeaseFrom`}
                  >
                    <LeaseDate unix={lease.LeaseFrom} />
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow key={"LeaseTo"} data-cy={`attribute-LeaseTo`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Lease To
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`LeaseTo`}
                  >
                    <LeaseDate unix={lease.LeaseTo} />
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow key={"SignDate"} data-cy={`attribute-SignDate`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Sign Date
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`SignDate`}
                  >
                    <LeaseDate unix={lease.SignDate} />
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow key={"MoveIn"} data-cy={`attribute-MoveIn`}>
                  <AttributeLabel data-cy={`attributeLabel`}>
                    Move In
                  </AttributeLabel>
                  <AttributeValue
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    data-cy={`MoveIn`}
                  >
                    <LeaseDate unix={lease.MoveIn} />
                  </AttributeValue>
                </AttributeRow>
              </GridAttributes>
            </GridContainer>
            <GridContainer
              sx={{
                pr: 0,
                mt: "3rem",
              }}
              data-cy={`section-company`}
            >
              <GridTitleWrapper
                sx={{ borderBottom: `1px solid ${borderColor}`, pb: "1.3rem" }}
              >
                <NoteAltIcon />
                <Title variant="h6" sx={{}}>
                  Notes
                </Title>
              </GridTitleWrapper>
              <GridAttributes>
                {lease.notes && (
                  <ReadOnlyTextEditior value={JSON.parse(notes)} />
                )}
              </GridAttributes>
            </GridContainer>

            <Stack direction="row" sx={{ fontSize: "14px", mt: "2rem" }}>
              <Typography variant="p" sx={{ mr: "1rem" }} color="neutral.main">
                Created {dayjs().to(dayjs(lease.createdAt))} by{" "}
                {lease.createdBy.firstName} {lease.createdBy.lastName}
              </Typography>
              {lease.updatedBy?.userId && (
                <Typography
                  variant="p"
                  sx={{ ml: "auto" }}
                  color="neutral.main"
                >
                  Last updated {dayjs().to(dayjs(lease.updatedAt))} by{" "}
                  {lease.updatedBy.firstName} {lease.updatedBy.lastName}
                </Typography>
              )}
            </Stack>
          </Paper>

          <LeaseComments commercialLeaseId={commercialLeaseId} />
        </Stack>
      </Stack>
    </Stack>
  );
}

function Lease() {
  let { id } = useParams();
  const { data, loading, refetch } = useQuery(GET_COMMERCIAL_LEASE, {
    variables: { commercialLeaseId: Number(id) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  if (loading || !data?.commercialLease) {
    return <Loading sx={{ mt: "15rem" }} />;
  }

  return <ViewLease lease={data?.commercialLease} refetch={refetch} />;
}

export default Lease;
