import { gql } from "@apollo/client";

export default gql`
  query UsersPropertiesAdmin($userId: Int!) {
    usersPropertiesAdmin(userId: $userId) {
      propertyId
      SourceKey
      PropertyKey
      PropertyName
    }
  }
`;
