import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import { useState, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import { useQuery } from "@apollo/client";
import { GET_ALL_PROPERTIES } from "queries";

function GlobalSearch() {
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const { data } = useQuery(GET_ALL_PROPERTIES);
  let navigate = useNavigate();
  const handleOpenSearch = _ => {
    setOpen(prev => !prev);

    if (!open) {
      // buggy stuff in mui: https://github.com/mui/material-ui/issues/1594
      setTimeout(_ => {
        inputRef.current.focus();
      }, 300);
    }
  };
  const handleSearch = (event, value) => {
    if (value.propertyId) {
      navigate(`/property/features/details?property=${value.propertyId}`);
    } else {
      navigate(`/search?q=${value}`);
    }

    setOpen(false);
  };
  return (
    <Stack
      direction="row-reverse"
      spacing="10"
      sx={{ width: "100%", alignItems: "center", height: "62px", mr: "1rem" }}
    >
      <IconButton
        color="primary"
        aria-label="open search"
        onClick={handleOpenSearch}
      >
        <SearchIcon
          color="neutral"
          sx={{ fontSize: 20 }}
          data-cy="globalShowSearch"
        />
      </IconButton>

      <Collapse orientation={"horizontal"} in={open}>
        <Autocomplete
          sx={{ width: 400 }}
          freeSolo
          id="global-search"
          disableClearable
          options={data ? data.properties : []}
          onChange={handleSearch}
          getOptionLabel={opt => opt.displayName || opt.PropertyName || opt}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                type: "search",
                "data-cy": "globalSearch",
              }}
            />
          )}
        />
      </Collapse>
    </Stack>
  );
}

export default GlobalSearch;
