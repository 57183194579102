import React from "react";
import Main from "./components/Main";
import { ThemeProvider } from "@mui/material/styles";
import defaultTheme from "./theme";
import { BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import AuthProvider from "auth/AuthProvider";
import AuthApolloProvider from "AuthApolloProvider";
import CustomThemeContext from "contexts/CustomThemeContext";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const adaptedHistory = React.useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate],
  );
  return children({ history: adaptedHistory, location });
};

function App() {
  const [theme, setTheme] = React.useState(defaultTheme);

  return (
    <BrowserRouter>
      <AuthProvider>
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
          <AuthApolloProvider>
            <ThemeProvider theme={theme}>
              <CustomThemeContext.Provider
                setTheme={setTheme}
                defaultTheme={defaultTheme}
              >
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Main />
                  </LocalizationProvider>
                </SnackbarProvider>
              </CustomThemeContext.Provider>
            </ThemeProvider>
          </AuthApolloProvider>
        </QueryParamProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
