import { gql } from "@apollo/client";

export default gql`
  query GetReport($reportId: Int!) {
    report(id: $reportId) {
      reportId
      reportCode
      getReport
      displayName
      visibility
      pbiReports {
        pbiId
        dataset
        pbiPaginatedId
        type
      }
      publishes {
        orgId
        previewId
        publishId
        lastPublishedBy {
          userId
          email
          firstName
          lastName
        }
        lastPublishedAt
        lastStatus
      }
    }
  }
`;
