import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { NAICS_OPTIONS, LEASE_STAGES } from "utils/constants";
import TextEditor from "components/shared/TextEditor";
import { useQuery } from "@apollo/client";
import { GET_ALL_USERS } from "queries";
import { getDisplayName } from "utils/formatters/user";
import Loading from "components/shared/Loading";

function LeaseCompany({ control, errors, setValue, getValues }) {
  const { data, loading } = useQuery(GET_ALL_USERS);
  const users = data?.users;
  if (loading) return <Loading sx={{ mt: "5rem" }} />;
  return (
    <>
      <Controller
        name="LeaseName"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            label="Tenant Legal Name"
            {...field}
            error={errors.LeaseName}
            required
            data-cy="LeaseName"
          />
        )}
      />
      <Controller
        name="TenantDbaName"
        control={control}
        render={({ field }) => (
          <TextField
            label="Tenant DBA Name"
            {...field}
            error={errors.TenantDbaName}
            data-cy="TenantDbaName"
          />
        )}
      />
      <Controller
        control={control}
        name="NAICSCode"
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <InputLabel id="NAICSCode" required>
              Industry
            </InputLabel>
            <Select
              error={errors.orgId}
              labelId="NAICSCode"
              label="Industry"
              required
              {...field}
            >
              {NAICS_OPTIONS.map(({ id, displayName }) => (
                <MenuItem key={id} value={id}>
                  {displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="Stage"
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <InputLabel id="Stage" required>
              Lease Stage
            </InputLabel>
            <Select
              error={errors.orgId}
              labelId="Stage"
              label="Lease Stage"
              required
              {...field}
            >
              {Object.keys(LEASE_STAGES).map(stage => (
                <MenuItem key={stage} value={stage}>
                  {stage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="assignee"
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <InputLabel id="assignee" required>
              Assignee
            </InputLabel>
            <Select
              error={errors.orgId}
              labelId="assignee"
              label="Assignee"
              required
              {...field}
            >
              {users.map(user => (
                <MenuItem key={user.userId} value={user.userId}>
                  {getDisplayName(user)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <TextEditor
        initialValue={getValues("notes")}
        placeholder="Notes"
        onChange={value => {
          setValue("notes", value);
        }}
      />
    </>
  );
}

export default LeaseCompany;
