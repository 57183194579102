import { gql } from "@apollo/client";

export default gql`
  query GetOrgs {
    orgs {
      orgId
      displayName
      theme
      logo
    }
  }
`;
