import { gql } from "@apollo/client";

export default gql`
  mutation UpdateProviderGroup(
    $updateProviderGroupInput: UpdateProviderGroupInput!
  ) {
    updateProviderGroup(updateProviderGroupInput: $updateProviderGroupInput) {
      providerGroupId
    }
  }
`;
