import { gql } from "@apollo/client";

export default gql`
  query GetAllUsersWithProperties($propertyIds: [Int!]!) {
    users: usersWithPropertiesAccess(propertyIds: $propertyIds) {
      userId
      email
      firstName
      lastName
      company
      title
      orgs {
        orgId
        displayName
        roleKey
        primary
        hasAllProperties
      }
      __typename
    }
  }
`;
