import { gql } from "@apollo/client";

export default gql`
  query AvailableBrokerProperties($brokerGroupId: Int) {
    availableBrokerProperties(brokerGroupId: $brokerGroupId) {
      propertyId
      PropertyName
      Type
      subtype
      leasingStage
      Class
      TotalSQFT
      NumberOfUnits
      acreage
      website
      Address
      City
      State
      Zip
      Submarket
      MSA
      County
      totalParking
      reservedParking
      coveredParking
      amenities
      PropertyManager
      YearBuilt
      PropertyKey
      SourceKey
      orgId
    }
  }
`;
