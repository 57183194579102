import { useState, useContext } from "react";
import Box from "@mui/material/Box";
// import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
// import { useNavigate } from "react-router";
import CurrentUserContext from "contexts/CurrentUserContext";
import { useAuth0 } from "@auth0/auth0-react";
// import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { getDisplayName } from "utils/formatters/user";
import { Avatar, Chip, Typography, useTheme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Stack from "@mui/material/Stack";
import { getDisplayName as getRole } from "utils/formatters/role";
import { useNavigate } from "react-router-dom";
// From https://mui.com/components/menus/
export default function ProfileMenu() {
  const user = useContext(CurrentUserContext.Context);
  const { logout, user: authUser } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!user) return null;
  // Don't shor gravatar, though it would be cool to...
  const pictureUrl = authUser?.picture?.match(/gravatar/)
    ? ""
    : authUser.picture;

  // const pictureUrl = authUser.picture;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          height: 62,
          ml: "1rem",
        }}
      >
        <Tooltip title={getDisplayName(user)}>
          <IconButton onClick={handleClick}>
            <Avatar
              alt={getDisplayName(user)}
              src={pictureUrl}
              imgProps={{ referrerPolicy: "no-referrer" }}
              data-cy="profileAvatar"
              sx={{
                width: "30px",
                height: "30px",
                fontSize: "1.2rem",
                bgcolor: theme.palette.primary.main,
              }}
            >
              {user?.firstName?.[0]} {user?.lastName?.[0]}
            </Avatar>
            {/* <img src={authUser.picture} alt="ok" /> */}
            {/* <PermIdentityIcon color="neutral" sx={{ fontSize: 20 }} /> */}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            p: " 1.5rem 2rem",
            cursor: "auto",
            ":hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <Stack direction="column" alignContent="center">
            <Avatar
              alt={getDisplayName(user)}
              src={pictureUrl}
              imgProps={{ referrerPolicy: "no-referrer" }}
              sx={{
                width: "5rem",
                height: "5rem",
                fontSize: "2rem",
                bgcolor: theme.palette.primary.main,
                mr: "auto",
                ml: "auto",
                mt: "1rem",
              }}
              fontSize="small"
            >
              {user?.firstName?.[0]} {user?.lastName?.[0]}
            </Avatar>

            <Box sx={{ mt: "1rem" }}>
              <Typography fontSize="2rem">{getDisplayName(user)}</Typography>
            </Box>
            <Box sx={{ display: "flex", mt: "0.3rem" }}>
              <Chip
                label={getRole(user.orgs[0].roleKey)}
                size="small"
                sx={{ ml: "auto", mr: "auto" }}
              />
            </Box>
          </Stack>
        </MenuItem>
        {/* <MenuItem>
          <Avatar /> Profile
        </MenuItem> */}
        <Divider />
        <MenuItem
          onClick={_ => {
            navigate("/help");
          }}
        >
          <ListItemIcon>
            <HelpIcon fontSize="small" color="primary" />
          </ListItemIcon>
          Help
        </MenuItem>
        <MenuItem
          onClick={() =>
            logout({ returnTo: `${window.location.origin}/login` })
          }
        >
          <ListItemIcon>
            <Logout fontSize="small" color="primary" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
