import Box from "@mui/material/Box";
import loading from "images/loading.gif";
import { styled } from "@mui/material/styles";
const Img = styled("img")`
  width: 10rem;
  height: 10rem;
  margin: 1rem auto;
`;
export default function Loading({ sx = {} }) {
  return (
    <Box
      sx={{ width: "100%", display: "flex", ...sx }}
      data-cy="loadingIndicator"
    >
      <Img src={loading} alt="loading..." />
    </Box>
  );
}
