import { gql } from "@apollo/client";
import { PROPERTY_FIELDS } from "./fragments";

export default gql`
  query GetMe($q: String) {
    me {
      email
      userId
      firstName
      lastName
      orgs {
        displayName
        orgId
        primary
        theme
        logo
        roleId
        roleKey
        hasAllProperties
        featureReporting
        featureDataProvider
        ownerSourceKey
        featureLeasing
      }
    }
    properties(q: $q) {
      propertyId
      PropertyName
      Type
      subtype
      leasingStage
      Class
      TotalSQFT
      NumberOfUnits
      acreage
      website
      Address
      City
      State
      Zip
      Submarket
      MSA
      County
      totalParking
      reservedParking
      coveredParking
      amenities
      PropertyManager
      YearBuilt
      PropertyKey
      SourceKey
      orgId
      __typename
    }
    providerGroup: myProviderGroup {
      displayName
      sourceKey
    }
  }
`;
