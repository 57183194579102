import { gql } from "@apollo/client";

export default gql`
  mutation CreateProviderFile(
    $createProviderFileInput: CreateProviderFileInput!
  ) {
    createProviderFile(createProviderFileInput: $createProviderFileInput) {
      providerFileId
    }
  }
`;
