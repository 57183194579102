import { gql } from "@apollo/client";

export default gql`
  query AvailableBrokerMembers($brokerGroupId: Int) {
    availableBrokerMembers(brokerGroupId: $brokerGroupId) {
      userId
      email
      firstName
      lastName
      company
      title
      orgs {
        orgId
        displayName
        roleKey
        primary
        hasAllProperties
      }
    }
  }
`;
