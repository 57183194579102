export function getDisplayName(user, options = { short: false, you: {} }) {
  if (!user) return "";
  const { firstName, lastName } = user;
  const { short, you } = options;
  if (you?.userId && user?.userId && user.userId === you.userId) {
    return "You";
  }
  if (short) {
    return firstName;
  }
  return `${firstName} ${lastName}`;
}
