import { gql } from "@apollo/client";

export default gql`
  query providerFiles {
    providerFiles {
      providerFileId
      status
      orgId
      fileName
      feedbackFileName
      createdBy {
        firstName
        lastName
        userId
      }
      providerGroup {
        providerGroupId
        displayName
      }
      createdAt
      size
      fileType
    }
  }
`;
