import { gql } from "@apollo/client";
import { PROPERTY_FIELDS } from "./fragments";
export default gql`
  ${PROPERTY_FIELDS}
  query GetPropertiesAdmin($q: String) {
    properties: propertiesAdmin(q: $q) {
      ...PropertyFields
    }
  }
`;
