import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import CurrentUserContext from "contexts/CurrentUserContext";

export default function usePageViews(tabs) {
  const { orgs } = useContext(CurrentUserContext.Context);

  let location = useLocation();
  useEffect(() => {
    //   ga.send(["pageview", location.pathname]);
    window?.gtag("event", "page_view", {
      page_path: location?.pathname,
      orgId: orgs?.[0]?.orgId,
    });
  }, [location]);
}
