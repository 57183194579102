import { Select, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CurrentUserContext from "contexts/CurrentUserContext";
import { PROPERTY_TYPES } from "utils/constants";
import { useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { GET_ALL_USERS } from "queries";
import { getDisplayName } from "utils/formatters/user";

function LeaseFilters({ filters, setFilters }) {
  const theme = useTheme();

  const { properties } = useContext(CurrentUserContext.Context);
  const selectedProperties = filters.property;
  const selectedAssignees = filters.assignee;
  const availableProperties = properties.filter(
    ({ Type }) => Type === PROPERTY_TYPES.COMMERCIAL,
  );
  const { data } = useQuery(GET_ALL_USERS);
  const users = data?.users;
  return (
    <Box sx={{ ml: "2rem", mt: "-0.3rem" }}>
      <FormControl size="small" sx={{ minWidth: "22.8rem" }}>
        <InputLabel id="property" required>
          Property
        </InputLabel>
        <Select
          labelId="property"
          label="Property"
          required
          onChange={val => {
            setFilters(prev => ({ ...prev, property: val?.target?.value }));
          }}
          multiple
          value={selectedProperties}
          renderValue={values => {
            return (
              <Box
                sx={{
                  fontWeight: 500,
                }}
              >
                {availableProperties?.length > 0 &&
                  values.map((val, index) =>
                    index < 2
                      ? `${
                          availableProperties.find(
                            ({ propertyId }) => propertyId.toString() === val,
                          )?.PropertyName
                        }${index < values.length - 1 && index < 1 ? ", " : ""}`
                      : "",
                  )}
                {values.length > 2 && (
                  <Box
                    sx={{ color: theme.palette.primary.main, ml: "0.4rem" }}
                    component="span"
                  >
                    +{values.length - 2} more
                  </Box>
                )}
              </Box>
            );
          }}
        >
          {availableProperties.map(property => (
            <MenuItem
              key={property.propertyId}
              value={property.propertyId.toString()}
              sx={{ fontSize: "1.3rem" }}
            >
              <Checkbox
                sx={{ pl: 0, pr: "0.5rem" }}
                checked={
                  selectedProperties.find(propertyId => {
                    return propertyId === property.propertyId.toString();
                  })
                    ? true
                    : false
                }
              />
              <Box
                sx={{
                  mr: "0.5rem",
                  fontWeight: 500,
                  width: "5rem",
                }}
              >
                {property.PropertyName}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ minWidth: "22.8rem", ml: "2rem" }}>
        <InputLabel id="assignee" required>
          Assignee
        </InputLabel>
        <Select
          labelId="assignee"
          label="Assignee"
          required
          onChange={val => {
            setFilters(prev => ({ ...prev, assignee: val?.target?.value }));
          }}
          multiple
          value={selectedAssignees}
          renderValue={values => {
            return (
              <Box
                sx={{
                  fontWeight: 500,
                }}
              >
                {users?.length > 0 &&
                  values?.map((val, index) => {
                    const user = users.find(
                      ({ userId }) => userId.toString() === val,
                    );
                    return index < 2
                      ? `${getDisplayName(user)}${
                          index < values.length - 1 && index < 1 ? ", " : ""
                        }`
                      : "";
                  })}
                {values.length > 2 && (
                  <Box
                    sx={{ color: theme.palette.primary.main, ml: "0.4rem" }}
                    component="span"
                  >
                    +{values.length - 2} more
                  </Box>
                )}
              </Box>
            );
          }}
        >
          {users?.map(user => (
            <MenuItem
              key={user.userId}
              value={user.userId.toString()}
              sx={{ fontSize: "1.3rem", width: "100%" }}
            >
              <Checkbox
                sx={{ pl: 0, pr: "0.5rem" }}
                checked={
                  selectedAssignees.find(userId => {
                    return userId === user.userId.toString();
                  })
                    ? true
                    : false
                }
              />
              <Box
                sx={{
                  mr: "0.5rem",
                  fontWeight: 500,
                  width: "5rem",
                }}
              >
                {getDisplayName(user)}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default LeaseFilters;
