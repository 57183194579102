import { gql } from "@apollo/client";

export default gql`
  query GetUnits($propertyId: Int!) {
    units(propertyId: $propertyId) {
      unitId
      Floor
      Building
      UnitCode
      Unitkey
      FloorPlan
      SQFT
      Status
      LeaseTo
      LeaseName
    }
  }
`;
