export const PROPERTY_TYPES = {
  COMMERCIAL: "Commercial",
  RESIDENTIAL: "Residential",
  MIXED: "Mixed",
};

export const WORKSPACE = "5b929173-eb21-48ae-a03f-5779755f9d7c";
export const PERMISSIONS = {
  owner: "owner",
  admin: "admin",
  crex: "crex",
  manager: "manager",
  provider: "provider",
  broker: "broker",
};

export const FEATURES = {
  featureReporting: "featureReporting",
  featureDataProvider: "featureDataProvider",
  featureLeasing: "featureLeasing",
};

export const REFRESH_STATUSES = {
  Unknown: "Unknown",
  Completed: "Completed",
  Failed: "Failed",
};

export const OCCURRENCE_OPTIONS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const CADENCE_OPTIONS = ["weekly", "daily"];

export const PROPERTY_SECTIONS = [
  { text: "Dashboard", to: "portfolio", reporting: true },
  { text: "Features", to: "features" },
  { text: "Financials", to: "financials", reporting: true },
  { text: "Compliance", to: "compliance", reporting: true },
  { text: "Operations", to: "rentroll", reporting: true },
  { text: "Collections", to: "collections", reporting: true },
  { text: "Leasing", to: "leasing", reporting: true },
  { text: "Marketing", to: "marketing", reporting: true },
  { text: "Maintenance", to: "maintenance", reporting: true },
  { text: "Governance", to: "governance", reporting: true },
];

export const timeZone = "America/Chicago";
export const NAICS_OPTIONS = [
  { id: 11, displayName: "Agriculture, Forestry, Fishing and Hunting" },
  { id: 21, displayName: "Mining" },
  { id: 22, displayName: "Utilities" },
  { id: 23, displayName: "Construction" },
  { id: 31, displayName: "Manufacturing" },
  { id: 42, displayName: "Wholesale Trade" },
  { id: 44, displayName: "Retail Trade" },
  { id: 48, displayName: "Transportation and Warehousing" },
  { id: 51, displayName: "Information" },
  { id: 52, displayName: "Finance and Insurance" },
  { id: 53, displayName: "Real Estate Rental and Leasing" },
  { id: 54, displayName: "Professional, Scientific, and Technical Services" },
  { id: 55, displayName: "Management of Companies and Enterprises" },
  {
    id: 56,
    displayName:
      "Administrative and Support and Waste Management and Remediation Services",
  },
  { id: 61, displayName: "Educational Services" },
  { id: 62, displayName: "Health Care and Social Assistance" },
  { id: 71, displayName: "Arts, Entertainment, and Recreation" },
  { id: 72, displayName: "Accommodation and Food Services" },
  { id: 81, displayName: "Other Services (except Public Administration)" },
  { id: 92, displayName: "Public Administration" },
];
export const LEASE_STAGES = {
  Interested: "Interested",
  Touring: "Touring",
  Proposal: "Proposal",
  Leased: "Leased",
  Lost: "Lost",
  Cancelled: "Cancelled",
};
