export const getDisplayName = function (roleKey) {
  const map = {
    admin: "Admin",
    manager: "Manager",
    owner: "Owner",
    crex: "Crex Admin",
    provider: "Data Provider",
    broker: "Broker",
  };
  return map[roleKey];
};
