import { gql } from "@apollo/client";

export default gql`
  query ProviderGroups {
    providerGroups {
      displayName
      sourceKey
      orgId
      providerGroupId
      users {
        firstName
        lastName
        userId
      }
      properties {
        propertyId
        PropertyName
        PropertyKey
      }
    }
  }
`;
