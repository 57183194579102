import { useContext, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Governance from "../Governance";
import Help from "components/Help";
import CurrentUserContext from "contexts/CurrentUserContext";
import { PERMISSIONS, FEATURES } from "utils/constants";
import Loading from "components/shared/Loading";
import FidoStatus from "components/FidoStatus";
import CommercialLeasing from "components/CommercialLeasing";
import usePageViews from "hooks/usePageViews";

const ProviderPortal = lazy(() => import("components/ProviderPortal"));
const Admin = lazy(() => import("components/Admin"));
const Profile = lazy(() => import("components/Profile"));
const OwnerPortal = lazy(() => import("components/OwnerPortal"));
const Property = lazy(() => import("components/Property"));
const Home = lazy(() => import("components/Home"));
const PropertyList = lazy(() => import("components/Property/PropertyList"));
const Search = lazy(() => import("components/Search"));

function SecuredRoutes() {
  const { can } = useContext(CurrentUserContext.Context);
  const notOwner = can([
    PERMISSIONS.admin,
    PERMISSIONS.manager,
    PERMISSIONS.broker,
  ]);
  const canAdmin = can([PERMISSIONS.crex, PERMISSIONS.admin]);
  const canCrex = can([PERMISSIONS.crex]);
  const hasReportingFeature = can([], [FEATURES.featureReporting]);
  const hasProviderFeature = can([], [FEATURES.featureDataProvider]);
  const hasLeasingFeature = can([], [FEATURES.featureLeasing]);

  let redirect;

  if (hasReportingFeature) {
    redirect = "home";
  } else if (hasProviderFeature) {
    redirect = "fido";
  } else {
    redirect = "leasing";
  }
  usePageViews();

  return (
    <Suspense fallback={<Loading sx={{ mt: "10rem" }} />}>
      <Routes>
        {hasReportingFeature && notOwner && (
          <>
            <Route path="governance" element={<Governance />} />
          </>
        )}
        {((canAdmin && (hasReportingFeature || hasLeasingFeature)) ||
          canCrex) && <Route path="admin/*" element={<Admin />} />}
        {hasReportingFeature && (
          <>
            {/* Currently home is only for reporting */}
            <Route path="home" element={<Home />} />
            <Route path="ownerportal/*" element={<OwnerPortal />} />
          </>
        )}
        {(hasReportingFeature || hasLeasingFeature) && notOwner && (
          <>
            <Route path="property" element={<PropertyList />} />
            <Route path="profile/:userId" element={<Profile />} />
            <Route path="property/*" element={<Property />} />
            <Route path="search" element={<Search />} />
          </>
        )}
        {hasProviderFeature && (
          <Route path="fido/*" element={<ProviderPortal />} />
        )}
        {hasProviderFeature && canAdmin && (
          <Route path="fidostatus/*" element={<FidoStatus />} />
        )}
        {hasLeasingFeature && (
          <Route path="leasing/*" element={<CommercialLeasing />} />
        )}
        {/* Everyone gets these */}
        <Route path="help" element={<Help />} />
        <Route
          path="*"
          element={<Navigate to={`/${redirect}`} replace={true} />}
        />
      </Routes>
    </Suspense>
  );
}

export default SecuredRoutes;
