import Box from "@mui/material/Box";
import { forwardRef } from "react";
import useWindow from "hooks/useWIndow";

const EmbedElement = forwardRef(({ sx, "data-cy": dataCy }, ref) => {
  const { height, chromeHeight } = useWindow();
  // takes into account the chrome of the app and the property selection etc
  const adjustment = chromeHeight + 150;
  return (
    <Box
      data-cy={dataCy}
      ref={ref}
      sx={{
        ...sx,
        width: "100%",
        height: sx.height || height - adjustment,
        iframe: {
          border: "none",
        },
      }}
    />
  );
});
export default EmbedElement;
