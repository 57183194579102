export function buildAddress(
  { Address, City, State, Zip },
  options = { short: false },
) {
  if (options.short) {
    return `${City ? City + ", " : ""}${State || ""}`;
  } else {
    return `${Address ? Address + ", " : ""}${City ? City + ", " : ""}${
      State ? `${State} ` : ""
    }${Zip || ""}`;
  }
}
