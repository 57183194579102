import React, { useCallback, useEffect, useState } from "react";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
// import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";

function AuthApolloProvider({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  const [client, setClient] = useState();
  // const [persistor, setPersistor] = useState();

  //Persist the cache. Let's be performant!
  //Secondary note, let's figure out suspense when React 18 comes out
  useEffect(_ => {
    const getClient = async _ => {
      const cache = new InMemoryCache({
        typePolicies: {
          Property: {
            keyFields: ["propertyId"],
          },
          Portfolio: {
            keyFields: ["portfolioId"],
          },
          Report: {
            keyFields: ["reportId"],
          },
          User: {
            keyFields: ["userId"],
          },
          Document: {
            keyFields: ["documentId"],
          },
          Doctag: {
            keyFields: ["doctagId"],
          },
        },
      });

      /*****  Start persistent code, uncomment me some day! *****/
      // let newPersistor = new CachePersistor({
      //   cache,
      //   storage: new LocalStorageWrapper(window.localStorage),
      //   debug: true,
      //   trigger: 'write',
      // });
      // await newPersistor.restore();
      // setPersistor(newPersistor);
      /***** End persistent code *****/

      const httpLink = createHttpLink({
        uri: process.env.REACT_APP_GRAPH_URL,
      });

      const authLink = setContext(async (_, { headers, ...context }) => {
        const token = await getAccessTokenSilently();

        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
          ...context,
        };
      });

      setClient(
        new ApolloClient({
          link: authLink.concat(httpLink),
          cache,
        }),
      );
    };
    getClient();
  }, []);

  // const clearCache = useCallback(() => {
  //   if (!persistor) {
  //     return;
  //   }
  //   persistor.purge();
  // }, [persistor]);

  // const reload = useCallback(() => {
  //   window.location.reload();
  // }, []);

  if (!client) {
    return null;
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
export default AuthApolloProvider;
