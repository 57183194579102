import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { GET_DEFINITIONS } from "queries";
import { useQuery } from "@apollo/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import CurrentUserContext from "contexts/CurrentUserContext";
import { PERMISSIONS } from "utils/constants";
import { Stack } from "@mui/material";

const sort = (a, b) => {
  console.info(a, b);
  const aDisplayName = a.displayName;
  const bDisplayName = b.displayName;
  return aDisplayName.localeCompare(bDisplayName);
};

function Definitions() {
  const { data, loading } = useQuery(GET_DEFINITIONS);
  const { can } = useContext(CurrentUserContext.Context);

  const definitions = data?.definitions ? [...data.definitions].sort(sort) : [];

  const isCrex = can([PERMISSIONS.crex]);
  // const isCrex = false;

  /*
    HELP CENTER title
    sticky header on table
    data source : string
    property type : string
    category : string
  */

  return (
    <Stack direction="row" sx={{ m: "auto", mt: "3rem" }}>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "150rem",
        }}
      >
        <TableContainer sx={{ maxHeight: "calc(100vh - 32rem)" }}>
          <Table stickyHeader aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: "neutral.light" }}>
                  Name
                </TableCell>
                <TableCell
                  sx={{ width: "67rem", backgroundColor: "neutral.light" }}
                >
                  Definition
                </TableCell>
                <TableCell sx={{ backgroundColor: "neutral.light" }}>
                  Source System
                </TableCell>
                <TableCell sx={{ backgroundColor: "neutral.light" }}>
                  Property Type(s)
                </TableCell>
                <TableCell sx={{ backgroundColor: "neutral.light" }}>
                  Category
                </TableCell>
                {isCrex && (
                  <>
                    <TableCell sx={{ backgroundColor: "neutral.light" }}>
                      Expression
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "neutral.light" }}>
                      Source
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "neutral.light" }}>
                      Org ID
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {definitions.map(row => (
                <TableRow
                  key={row.definitionId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.displayName}
                  </TableCell>
                  <TableCell>{row.humanDefinition}</TableCell>
                  <TableCell>{row.sourceSystem}</TableCell>
                  <TableCell>{row.propertyType}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  {isCrex && (
                    <>
                      <TableCell>{row.expression}</TableCell>
                      <TableCell>{row.sourceName}</TableCell>
                      <TableCell>{row.orgId || "Global"}</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
}

export default Definitions;
