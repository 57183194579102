import { gql } from "@apollo/client";

export default gql`
  query GetLeases($filters: CommercialLeaseFilters) {
    commercialLeases(filters: $filters) {
      commercialLeaseId
      Stage
      LeaseFrom
      LeaseName
      LeaseTo
      LsType
      MoveIn
      MiscellaneousPerArea
      NAICSCode
      RecoveryPerArea
      GrossRentAnnualPerArea
      SecurityDeposit
      SignDate
      TenantDbaName
      units {
        unitId
        Unitkey
        UnitCode
        FloorPlan
        Floor
        Building
      }
      property {
        PropertyName
        propertyId
      }
      notes
      assignee {
        userId
        firstName
        lastName
      }
    }
  }
`;
