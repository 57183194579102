import { gql } from "@apollo/client";

export default gql`
  query Definitions {
    definitions {
      displayName
      expression
      humanDefinition
      orgId
      reports
      sourceName
      definitionId
      sourceSystem
      propertyType
      category
    }
  }
`;
