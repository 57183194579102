import { gql } from "@apollo/client";

export default gql`
  mutation CreateProviderGroup(
    $createProviderGroupInput: CreateProviderGroupInput!
  ) {
    createProviderGroup(createProviderGroupInput: $createProviderGroupInput) {
      providerGroupId
    }
  }
`;
