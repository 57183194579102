import { gql } from "@apollo/client";

export default gql`
  query GetReportsAdmin {
    reports: reportsAdmin {
      reports {
        reportId
        displayName
        internal
        orgId
        section
        reportCode
        getReport
        visibility
        pbiReports {
          pbiId
          dataset
          pbiPaginatedId
          type
        }
      }
    }
  }
`;
