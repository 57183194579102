import Chip from "@mui/material/Chip";
export function getStageColor(stage) {
  let color;
  switch (stage) {
    case "Interested":
      color = "interested";
      break;
    case "Proposal":
      color = "proposal";
      break;
    case "Touring":
      color = "touring";
      break;
    case "Leased":
      color = "success";
      break;
    case "Lost":
      color = "lost";
      break;
    case "Cancelled":
    default:
      color = "neutral";
  }

  return color;
}

function LeaseStage({ stage }) {
  return (
    <Chip
      label={stage}
      size="small"
      color={getStageColor(stage)}
      sx={{ mr: "1rem" }}
    />
  );
}

export default LeaseStage;
