import { gql } from "@apollo/client";

export default gql`
  query GetEmbed {
    embed {
      token
      tokenId
      expiration
    }
  }
`;
