import { useContext, useState, useEffect, useRef } from "react";
import useEmbed from "hooks/useEmbed";
import Embed from "components/shared/EmbedElement";
import ReportsContext from "contexts/ReportsContext";
import Loading from "components/shared/Loading";
import { Paper, Box } from "@mui/material";
function AdminReport({ reportCode = "_adminUserEngagement" }) {
  const [report, setReport] = useState({});
  const { getReportByCode } = useContext(ReportsContext.Context);
  const containerRef = useRef();
  const [pbiReport, setPbiReport] = useState();
  const { loaded } = useEmbed(pbiReport, containerRef);
  useEffect(
    _ => {
      setReport(getReportByCode(reportCode));
    },
    [reportCode, getReportByCode],
  );
  useEffect(
    _ => {
      if (report?.pbiReports) {
        setPbiReport(report.pbiReports[0]);
      }
    },
    [report],
  );

  return (
    <Box sx={{ p: "2rem", width: "100%", height: "100%" }}>
      <Paper sx={{ width: "100%", height: "100%", pt: loaded ? 0 : "15rem" }}>
        {!loaded && <Loading />}
        <Embed
          data-cy={`report-${pbiReport?.pbiId}`}
          ref={containerRef}
          sx={{ display: loaded ? "auto" : "none", height: "100%" }}
        />
      </Paper>
    </Box>
  );
}

export default AdminReport;
