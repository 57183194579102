import { gql } from "@apollo/client";

export default gql`
  query GetAllDocumentsAdmin {
    documents: documentsAdmin {
      displayName
      fileName
      contentType
      createdAt
      container
      documentId
      orgId
      size
      tags {
        orgId
        doctagId
        displayName
      }
      users {
        userId
        firstName
        lastName
      }
      createdBy {
        userId
        firstName
        lastName
      }
    }
  }
`;
