import { createContext, useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_ALL_PROPERTIES,
  GET_ALL_USERS,
  GLOBAL_SEARCH,
  GET_ALL_PORTFOLIOS,
} from "queries";
import debounce from "lodash/debounce";
const Context = createContext({});
Context.displayName = "SearchResultsContext";

/*
 * Used to handle global search functionality
 * q: search query
 * en: entity to search
 */
const Provider = ({ children, query, setQuery, _component = "default" }) => {
  const [
    getAllProperties,
    {
      data: propertiesData,
      loading: propertiesLoading,
      refetch: propertiesRefetch,
    },
  ] = useLazyQuery(GET_ALL_PROPERTIES);
  const [
    getAllPeople,
    { data: peopleData, loading: peopleLoading, refetch: peopleRefetch },
  ] = useLazyQuery(GET_ALL_USERS);
  const [
    globalSearch,
    { data: searchData, loading: searchLoading, refetch: searchRefetch },
  ] = useLazyQuery(GLOBAL_SEARCH);
  const [
    getAllPortfolios,
    {
      data: portfoliosData,
      loading: portfoliosLoading,
      refetch: portfoliosRefetch,
    },
  ] = useLazyQuery(GET_ALL_PORTFOLIOS);
  const gTagSearch = useCallback(
    debounce(options => {
      window?.gtag("event", "search", options);
    }, 1000),
    [],
  );
  useEffect(
    _ => {
      let input = {};
      if (query.q) {
        input = {
          fetchPolicy: "network-only",
          variables: { q: `%${query.q || ""}%` },
        };
      }
      switch (query.en) {
        case "portfolios":
          getAllPortfolios(input);
          break;
        case "properties":
          getAllProperties(input);
          break;
        case "people":
          getAllPeople(input);
          break;
        default:
          globalSearch(input);
          break;
      }

      query?.q &&
        gTagSearch({
          search_term: query.q,
          search_entity: query.en,
          search_component: _component,
        });
    },
    [
      query,
      getAllPeople,
      getAllProperties,
      getAllPortfolios,
      globalSearch,
      _component,
      gTagSearch,
    ],
  );

  const refetch = useCallback(
    _ => {
      switch (query.en) {
        case "portfolios":
          portfoliosRefetch();
          break;
        case "properties":
          propertiesRefetch();
          break;
        case "people":
          peopleRefetch();
          break;
        default:
          searchRefetch();
          break;
      }
    },
    [query, peopleRefetch, propertiesRefetch, searchRefetch, portfoliosRefetch],
  );

  let results;
  const people = peopleData ? peopleData.users : [];
  const properties = propertiesData ? propertiesData.properties : [];
  const search = searchData ? searchData.search : [];
  const portfolios = portfoliosData ? portfoliosData.portfolios : [];
  const loading =
    propertiesLoading || portfoliosLoading || searchLoading || peopleLoading;
  switch (query.en) {
    case "people":
      results = people;
      break;
    case "properties":
      results = properties;
      break;
    case "portfolios":
      results = portfolios;
      break;
    default:
      results = search;
      break;
  }

  return (
    <Context.Provider value={{ results, query, setQuery, loading, refetch }}>
      {children}
    </Context.Provider>
  );
};
const SearchContext = { Context, Provider };
export default SearchContext;
