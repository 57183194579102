import { gql } from "@apollo/client";

export default gql`
  mutation UpdateCommercialLease(
    $updateCommercialLeaseInput: UpdateCommercialLeaseInput!
  ) {
    updateCommercialLease(
      updateCommercialLeaseInput: $updateCommercialLeaseInput
    ) {
      commercialLeaseId
    }
  }
`;
