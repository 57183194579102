import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function ConfirmationDialog({
  handleConfirm,
  handleClose,
  open,
  title,
  text,
  confirmText = "Confirm",
  cancelText = "Cancel",
  children,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy="confirmationDialog"
    >
      <Stack direction="row">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <IconButton
          sx={{
            color: "#C2CFE0",
            p: 0,
            mt: "1.8rem",
            height: "25px",
            ml: "auto",
            mr: "0.8rem",
          }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Stack>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text ? text : children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button sx={{ textTransform: "none" }} onClick={handleClose}>
            {cancelText}
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={e => {
            e.preventDefault();
            handleClose();
            handleConfirm();
          }}
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
