import { useState, useContext, useCallback } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CurrentUserContext from "contexts/CurrentUserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { PROPERTY_TYPES } from "utils/constants";
import LeaseLocation from "./LeaseLocation";
import LeaseCompany from "./LeaseCompany";
import LeaseTerms from "./LeaseTerms";
import dayjs from "dayjs";

import StepButton from "@mui/material/StepButton";
const steps = ["Tenant", "Location", "Lease"];

function CommercialLeaseForm({
  values = {},
  title = "Edit Prospect",
  Action,
  onSubmit,
}) {
  const [open, setOpen] = useState(false);
  // steps: 0 company  -> 1 location -> 2 lease
  const [step, setStep] = useState(0);

  function nextStep() {
    setStep(prevActiveStep => {
      return prevActiveStep + 1;
    });
  }

  function previousStep() {
    setStep(prevActiveStep => prevActiveStep - 1);
  }

  const { properties, userId } = useContext(CurrentUserContext.Context);

  const availableProperties = properties.filter(
    ({ Type }) => Type === PROPERTY_TYPES.COMMERCIAL,
  );
  const [SpaceType, LsType] = values?.LsType
    ? values?.LsType.split(" ")
    : [null, null];

  const {
    control,
    handleSubmit,
    formState,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      LeaseName: values?.LeaseName || "",
      TenantDbaName: values?.TenantDbaName || "",
      GrossRentAnnualPerArea: values?.GrossRentAnnualPerArea || null,
      RecoveryPerArea: values?.RecoveryPerArea || null,
      MiscellaneousPerArea: values?.MiscellaneousPerArea || null,
      SecurityDeposit: values?.SecurityDeposit || null,
      propertyId: values?.property?.propertyId || null,
      LeaseTo: values?.LeaseTo ? dayjs(values?.LeaseTo) : null,
      LeaseFrom: values?.LeaseFrom ? dayjs(values?.LeaseFrom) : null,
      SignDate: values?.SignDate ? dayjs(values?.SignDate) : null,
      MoveIn: values?.MoveIn ? dayjs(values?.MoveIn) : null,
      Stage: values?.Stage || null,
      LsType,
      SpaceType,
      NAICSCode: values?.NAICSCode || null,
      units: values?.units ? values?.units.map(({ unitId }) => unitId) : [],
      assignee: values?.assignee?.userId ? values.assignee.userId : userId,
      notes: values?.notes
        ? JSON.parse(values?.notes)
        : [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ],
    },
  });

  const { errors } = formState;
  const [saving, setSaving] = useState(false);

  const watchProperty = watch("propertyId");
  const watchLsType = watch("LsType");

  const isGrossType = watchLsType && "Gross" === watchLsType;
  const watchUnit = watch("units");
  const watchNAICS = watch("NAICSCode");

  const watchLegalName = watch("LeaseName");
  const watchStage = watch("Stage");
  const watchLeaseFrom = watch("LeaseFrom");

  const watchGrossRentAnnualPerArea = watch("GrossRentAnnualPerArea");
  const watchRecoveryPerArea = watch("RecoveryPerArea");
  const watchMiscellaneousPerArea = watch("MiscellaneousPerArea");
  const watchSecurityDeposit = watch("SecurityDeposit");
  const watchAssignee = watch("assignee");
  const isDisabled = useCallback(
    function isDisabled(currentStep = step) {
      const isGrossType = watchLsType && "Gross" === watchLsType;
      let disabled;
      switch (currentStep) {
        case 0:
          disabled = !(
            watchLegalName &&
            watchNAICS &&
            watchStage &&
            watchAssignee
          );
          break;
        case 1:
          console.info("return false");
          disabled = false; // all are optional
          break;
        default:
        case 2:
          disabled = !(
            watchLeaseFrom &&
            watchLsType &&
            watchGrossRentAnnualPerArea &&
            watchSecurityDeposit &&
            (isGrossType || (watchRecoveryPerArea && watchMiscellaneousPerArea))
          );
      }
      return disabled;
    },
    [
      step,
      watchLegalName,
      watchNAICS,
      watchStage,
      watchLeaseFrom,
      watchLsType,
      watchGrossRentAnnualPerArea,
      watchSecurityDeposit,
      watchRecoveryPerArea,
      watchMiscellaneousPerArea,
      watchAssignee,
    ],
  );

  async function handleAdd(formData) {
    if (step !== 2) {
      nextStep();
      return;
    }
    setSaving(true);

    await onSubmit(formData, handleClose);

    setSaving(false);
  }

  function handleClose() {
    setOpen(false);
    setStep(0);
    reset();
  }

  return (
    <>
      <Action setOpen={setOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit(handleAdd)} data-cy="addLeaseForm">
          <Stack
            direction="column"
            sx={{
              width: "60rem",
              // minHeight: "40rem",
              p: "2rem",
            }}
            rowGap={2}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5" sx={{ mb: "1rem" }}>
                {title}
              </Typography>
              <IconButton
                sx={{ color: "#C2CFE0", p: 0, height: "25px" }}
                onClick={handleClose}
              >
                <CloseIcon sx={{ fontSize: 25 }} />
              </IconButton>
            </Stack>
            <Stepper
              activeStep={step}
              nonLinear
              sx={{
                mb: "2rem",
              }}
            >
              {steps.map((label, index) => {
                const isPreviousStep = index < step;
                let arePreviousStepsValid = true;

                // if all steps prior to this step are valid, can click
                for (let cur = 0; cur < index; cur++) {
                  if (isDisabled(cur)) {
                    arePreviousStepsValid = false;
                  }
                }
                const isClickable = isPreviousStep || arePreviousStepsValid;
                const isCurrentStep = step === index;

                return (
                  <Step key={label}>
                    {isClickable ? (
                      <StepButton
                        onClick={_ => setStep(index)}
                        sx={{
                          ".MuiSvgIcon-root": {
                            color: !isCurrentStep
                              ? "primary.light"
                              : "primary.main",
                          },
                        }}
                      >
                        {label}
                      </StepButton>
                    ) : (
                      <StepLabel>{label}</StepLabel>
                    )}
                  </Step>
                );
              })}
            </Stepper>
            {step === 0 && (
              <LeaseCompany
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
              />
            )}
            {step === 1 && (
              <LeaseLocation
                watch={watch}
                availableProperties={availableProperties}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            )}
            {step === 2 && (
              <LeaseTerms control={control} watch={watch} errors={errors} />
            )}
            <Stack
              direction="row"
              justifyContent={"flex-end"}
              sx={{ mt: "1rem" }}
            >
              <Button onClick={handleClose}>Cancel</Button>
              {step !== 0 && (
                <Button
                  variant="outlined"
                  onClick={previousStep}
                  sx={{ ml: "1rem" }}
                >
                  Back
                </Button>
              )}
              <LoadingButton
                sx={{ ml: "1rem", textTransform: "none" }}
                type="submit"
                variant="contained"
                disabled={isDisabled()}
                loading={saving}
              >
                {step === 2 ? "Submit" : "Next"}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Dialog>
    </>
  );
}

export default CommercialLeaseForm;
