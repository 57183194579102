import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/shared/Loading";

export default function Login() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return null;
}
