import LeaseList from "./LeaseList";
import { Routes, Route } from "react-router-dom";
import Lease from "./Lease";

function CommercialLeasing() {
  return (
    <Routes>
      <Route path="lease/:id" element={<Lease />} />
      <Route path="" element={<LeaseList />} />
    </Routes>
  );
}

export default CommercialLeasing;
