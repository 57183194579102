import Stack from "@mui/material/Stack";
import AdminReport from "components/Admin/AdminReport";
import useWindow from "hooks/useWIndow";
import Paper from "@mui/material/Paper";
function FidoStatus() {
  const { height, chromeHeight } = useWindow();
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "#EBEFF2", height: height - chromeHeight }}
    >
      <Stack direction="row" sx={{ minHeight: "75rem", height: "100%" }}>
        <AdminReport reportCode="_adminFidoStatus" />
      </Stack>
    </Paper>
  );
}

export default FidoStatus;
