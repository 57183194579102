import { createTheme } from "@mui/material/styles";
import { orange, yellow, deepOrange, cyan } from "@mui/material/colors";
const theme = createTheme({
  typography: {
    // Tell MUI what's the font-size on the html element is.
    // Since we use 62.5%, 16(px)*.625 = 10
    fontFamily: "'Poppins'",
    htmlFontSize: 10,
  },
  palette: {
    white: {
      main: "#fff",
    },
    background: {
      default: "#F5F6F8",
    },
    primary: {
      light: "#9352DD",
      main: "#6B10D6",
      dark: "#2C0657",
      contrastText: "#fff",
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#A5A6F6",
      main: "#7879F1",
    },
    info: {
      light: "#098EDF",
      main: "#4D7298",
    },
    neutral: {
      light: "#EBEFF2",
      main: "#6A707E",
      contrastText: "#fff",
      dark: "#90a0b7",
    },
    yellow: {
      main: "#FFCC04",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    red: {
      main: "#FC5708",
      contrastText: "#fff",
    },
    orange: {
      main: orange[400],
      contrastText: "#fff",
    },
    lightYellow: {
      main: yellow[300],
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    darkGreen: {
      main: "#038A2B",
      contrastText: "#fff",
    },
    lightRed: {
      main: deepOrange[200],
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    lightCyan: {
      main: cyan[100],
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    interested: {
      main: "#faa726",
      contrastText: "#fff",
    },
    touring: {
      main: "#9352DD",
      contrastText: "#fff",
    },
    proposal: {
      main: "#0414D6",
      contrastText: "#fff",
    },
    lost: { main: "#FC5708", contrastText: "#fff" },
  },
});
export default theme;
