import { useState, useEffect, useContext } from "react";
import { models } from "powerbi-client";
import ReportsContext from "contexts/ReportsContext";
// import useInterval from "./useInterval";

const powerbi = window["powerbi"];
const defaultConfig = {
  type: "report", // Supported types: report, dashboard, tile, visual and qna
  accessToken: undefined, // Keep as empty string, null or undefined
  tokenType: models.TokenType.Embed,
  bootstrapped: true,
  settings: {
    background: models.BackgroundType.Transparent,
    panes: {
      pageNavigation: {
        visible: false,
      },
      filters: {
        visible: false,
      },
    },
  },
};

function useEmbed(reportConfig = {}, containerRef, getFilters = _ => {}) {
  const { pbiId: id } = reportConfig;

  const { embeds } = useContext(ReportsContext.Context);
  const [embedConfig, setEmbedConfig] = useState({
    ...defaultConfig,
  });

  const [storedReportId, setStoredReportId] = useState();
  const [report, setReport] = useState();
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState();
  // TODO: refresh token

  // Bootstrap immediately for performance
  useEffect(_ => {
    console.info(".bootstrap report");
    powerbi.bootstrap(containerRef.current, embedConfig);
  }, []);

  // When a report is loaded
  //  1) embed the report
  // When a report is changed to a different report
  //  1) reset the embed: powerbi.reset(element)
  //  2) embed a new report (bootstrap, then load)
  useEffect(
    _ => {
      if (id && embedConfig && embedConfig.accessToken) {
        const loadReport = async _ => {
          if (!storedReportId) {
            const curFilters = await getFilters(reportConfig);
            console.info(".load report");
            // setting up report for the first time
            let report = powerbi.load(containerRef.current, {
              ...embedConfig,
              filters: curFilters || [],
              id,
            });
            setReport(report);
            setStoredReportId(id);
          } else if (storedReportId !== id) {
            console.info(".reset, .bootstrap");
            // report we are looking at changes
            setLoaded(false);
            powerbi.reset(containerRef.current);
            powerbi.bootstrap(containerRef.current, embedConfig);
            setStoredReportId();
          }
        };
        loadReport();
      }
    },
    [id, embedConfig, storedReportId, containerRef],
  );

  useEffect(
    _ => {
      const currentToken = report?.config?.accessToken;
      const latestToken = embedConfig?.accessToken;
      if (currentToken && latestToken && currentToken !== latestToken) {
        console.info(".setAccessToken after change");
        // access token probably changed, set it
        report.setAccessToken(embedConfig.accessToken);
      }
    },
    [report, embedConfig],
  );
  // useInterval(async function syncFilters() {
  //   try {
  //     // const filters = await getFilters(reportConfig);

  //     // filters &&
  //     //   (await report.updateFilters(models.FiltersOperations.Replace, filters));
  //     const newFilters = await report.getFilters();
  //     console.info("Filters on report:", newFilters);
  //   } catch (error) {
  //     console.error("Issue syncing filters", error);
  //   }
  // }, 3000);

  // when loaded and properties change, apply filters
  useEffect(
    _ => {
      if (loaded) {
        async function syncFilters() {
          try {
            const filters = await getFilters(reportConfig);
            console.info(".updateFilters");
            filters &&
              (await report.updateFilters(
                models.FiltersOperations.Replace,
                filters,
              ));
            //   WTF: for some reason, this causes the power bi embedd (a message coming from in the iframe)
            //         to return the error prepare report required prior to render
            report.render();
            const newFilters = await report.getFilters();
            console.info("Filters on report:", newFilters);
          } catch (error) {
            console.error("Issue syncing filters", error);
          }
        }

        syncFilters();
      }
    },
    [loaded, getFilters, reportConfig, report],
  );
  // useEffect(
  //   _ => {
  //     async function test() {
  //       console.info("active page", (await report.getActivePage()).name);
  //       console.info("report", report);
  //       const bookmark = await report.bookmarksManager.capture();
  //       console.info("dod", bookmark, bookmark?.state);
  //     }
  //     const interval = setInterval(test, 4000);
  //     return _ => clearInterval(interval);
  //   },
  //   [report],
  // );
  // useEffect(
  //   _ => {
  //     async function test() {
  //       console.info("report", report);
  //       const filters = await report.getFilters();
  //       const bookmark = await report.bookmarksManager.capture();
  //       console.info("dod", bookmark, bookmark?.state);
  //       console.info("report filters", filters);
  //     }
  //     const interval = setInterval(test, 4000);
  //     return _ => clearInterval(interval);
  //   },
  //   [report],
  // );
  // useEffect(
  //   _ => {
  //     if (!report) return;
  //     async function updateBookmark(ar) {
  //       console.info("bbook", ar, ar?.detail?.bookmarkName);
  //     }
  //     report.on("bookmarkApplied", updateBookmark);
  //     return _ => report.off("bookmarkApplied", updateBookmark);
  //   },
  //   [report],
  // );
  useEffect(
    _ => {
      if (!report) return;
      async function updatePage(ar) {
        const newPage = ar?.detail?.newPage?.name;
        console.info("New report page", newPage);
        setPage(newPage);
      }
      report.on("pageChanged", updatePage);
      return _ => report.off("pageChanged", updatePage);
    },
    [report],
  );
  // when done loading, set loaded and render the report
  useEffect(
    _ => {
      if (report) {
        async function onLoaded(event) {
          console.info("onload", event);
          setLoaded(true);
        }
        async function onError(event) {
          console.error("onerror", event);
          const {
            message,
            detailedMessage,
            errorCode,
            level,
            technicalDetails,
          } = event?.detail || {};
          console.info("message", message);
          console.info("detailedMessage", detailedMessage);
          console.info("errorCode", errorCode);
          console.info("level", level);
          console.info("technicalDetails", technicalDetails);

          setLoaded(true);
        }
        report.on("loaded", onLoaded);
        report.on("error", onError);
        return _ => {
          report.off("loaded", onLoaded);
          report.off("error", onError);
        };
      }
    },
    [report],
  );

  // The accessToken is fetched from the backend, we can finish setting up the embed
  useEffect(
    _ => {
      const matchingEmbed = embeds.find(({ reportIds }) =>
        reportIds.includes(reportConfig.pbiId),
      );
      const accessToken = matchingEmbed?.token?.token;
      accessToken && console.info(".setEmbedConfig");
      accessToken &&
        setEmbedConfig(prev => ({
          ...prev,
          accessToken,
          tokenType: models.TokenType.Embed,
        }));
    },
    [embeds, reportConfig],
  );

  //TODO: add token refresh logic here: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/refresh-token

  return { embedConfig, loaded, embeddedReport: report, page };
}

export default useEmbed;
