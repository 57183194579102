import { gql } from "@apollo/client";

export default gql`
  query GenerateProviderBlobSAS($fileName: String, $feedbackFileName: String) {
    generateProviderFileSAS(
      fileName: $fileName
      feedbackFileName: $feedbackFileName
    )
  }
`;
