import { gql } from "@apollo/client";
import { MIN_PROPERTY_FIELDS, PORTFOLIO_FIELDS } from "./fragments";
export default gql`
  ${MIN_PROPERTY_FIELDS}
  ${PORTFOLIO_FIELDS}
  query GlobalSearch($q: String) {
    search(q: $q) {
      ...MinPropertyFields
      ...PortfolioFields
    }
  }
`;
