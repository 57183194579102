import { gql } from "@apollo/client";

export default gql`
  mutation CreateCommercialLease(
    $createCommercialLeaseInput: CreateCommercialLeaseInput!
  ) {
    createCommercialLease(
      createCommercialLeaseInput: $createCommercialLeaseInput
    ) {
      commercialLeaseId
    }
  }
`;
