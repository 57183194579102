import React, { forwardRef } from "react";
import classNames from "classnames";
import styles from "./Container.module.css";
import { Box } from "@mui/material";
import { getStageColor } from "../LeaseStage";
import { useTheme } from "@mui/material/styles";
export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      ...props
    },
    ref,
  ) => {
    const Component = onClick ? "button" : "div";
    const theme = useTheme();
    const colorKey = getStageColor(label);
    const colorComponents = colorKey.split(".");
    const color =
      colorComponents.length === 1
        ? theme.palette[colorKey]
        : theme.palette[colorComponents[0]][colorComponents[1]];
    return (
      <Component
        {...props}
        ref={ref}
        style={{
          ...style,
          "--columns": columns,
        }}
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          scrollable && styles.scrollable,
          shadow && styles.shadow,
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          <Box
            className={styles.Header}
            sx={{
              backgroundColor: color.main,
              color: color.contrastText,
              opacity: "0.9",
            }}
          >
            {label}
          </Box>
        ) : null}
        {placeholder ? (
          children
        ) : (
          <ul style={{ padding: "1rem", margin: 0 }}>{children}</ul>
        )}
      </Component>
    );
  },
);
