import { gql } from "@apollo/client";

export default gql`
  query GetReports {
    reports {
      reports {
        reportId
        displayName
        internal
        orgId
        section
        reportCode
        getReport
        visibility
        pbiReports {
          pbiId
          dataset
          pbiPaginatedId
          type
        }
        publishes {
          lastPublishedAt
        }
      }
      embeds {
        datasetIds
        reportIds
        token {
          expiration
          token
          tokenId
        }
      }
    }
  }
`;
