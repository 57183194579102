import { gql } from "@apollo/client";
import { PORTFOLIO_FIELDS } from "./fragments";
export default gql`
  ${PORTFOLIO_FIELDS}
  query GetPortfolios($q: String) {
    portfolios(q: $q) {
      ...PortfolioFields
    }
  }
`;
