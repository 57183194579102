// import { useContext } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Nav from "./Nav";
import GlobalSearch from "./GlobalSearch";
import crexLogo from "../../images/logo.png";
import ProfileMenu from "./ProfileMenu";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { FEATURES, PERMISSIONS } from "utils/constants";
import CurrentUserContext from "contexts/CurrentUserContext";
import { Stack } from "@mui/material";
// import CustomThemeContext from "contexts/CustomThemeContext";

const Logo = styled("img")`
  width: 91px;
  height: 31px;
  margin-bottom: 12px;
  cursor: pointer;
`;
// const CustomLogoContainer = styled("div")`
//   width: 91px;
//   height: 31px;
//   margin-bottom: 12px;
//   cursor: pointer;
//   position: relative;
// `;
// const SubContainer = styled("div")`
//   width: 100%;
//   position: absolute;
//   bottom: -16px;
//   display: flex;
//   flex-direction: row;
// `;
// const SubLogo = styled("img")`
//   width: 20px;
//   height: 10px;
// `;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "white",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow:
    "0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 2px 3px 0px rgb(0 0 0 / 6%), 0px 1px 8px 0px rgb(0 0 0 / 2%)",
}));

function LogoWithWhiteLabel() {
  // const { logo } = useContext(CustomThemeContext.Context);
  let navigate = useNavigate();
  // for when we support white label logo, maybe
  // if (logo) {
  //   return (
  //     <CustomLogoContainer>
  //       <Logo src={crexLogo} alt="Crex" onClick={_ => navigate("/home")} />
  //       <SubContainer>
  //         <Typography variant="p" sx={{ fontSize: "10px" }}>
  //           Powered By
  //         </Typography>
  //         <SubLogo src={crexLogo} alt="Crex" onClick={_ => navigate("/home")} />
  //       </SubContainer>
  //     </CustomLogoContainer>
  //   );
  // }
  return <Logo src={crexLogo} alt="Crex" onClick={_ => navigate("/home")} />;
}

function SearchPlaceholder() {
  return (
    <Stack
      direction="row-reverse"
      spacing="10"
      sx={{ width: "100%", alignItems: "center", height: "62px", mr: "1rem" }}
    ></Stack>
  );
}
function Chrome({ children }) {
  const { can } = useContext(CurrentUserContext.Context);
  const notOwner = can([PERMISSIONS.admin, PERMISSIONS.manager]);
  const hasReporting = can([], [FEATURES.featureReporting]);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed">
        <Toolbar sx={{ minHeight: 55, alignItems: "flex-end" }}>
          <LogoWithWhiteLabel />
          <Nav />
          {notOwner && hasReporting ? (
            <>
              <GlobalSearch />
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ height: "3rem", mt: "1.7rem" }}
              />
            </>
          ) : (
            <SearchPlaceholder />
          )}

          <ProfileMenu />
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ flexGrow: 1, height: "100%" }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

export default Chrome;
