import { gql } from "@apollo/client";

export default gql`
  query GetRoles {
    roles {
      roleId
      displayName
      roleKey
    }
  }
`;
