import React, { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Handle } from "./Handle";
import { Remove } from "./Remove";
import styles from "./Item.module.css";
import { styled } from "@mui/material/styles";
import { Stack, Box } from "@mui/material";
import CurrentUserContext from "contexts/CurrentUserContext";
import { PERMISSIONS } from "utils/constants";

const Link = styled(NavLink)`
  text-decoration: none;
  font-size: 14px;
`;
export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        totalItems,
        ...props
      },
      ref,
    ) => {
      const [currentItem, setCurrentItem] = useState({});
      const { can } = useContext(CurrentUserContext.Context);

      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      useEffect(
        _ => {
          totalItems &&
            setCurrentItem(
              totalItems.find(
                ({ commercialLeaseId }) => commercialLeaseId === value,
              ),
            );
        },
        [totalItems, value],
      );
      if (!currentItem) {
        return null;
      }
      const isBroker = can([PERMISSIONS.broker]);
      const {
        commercialLeaseId,
        LeaseName,
        LsType,
        property,
        units,
        LeaseFrom,
        Stage,
        TenantDbaName,
      } = currentItem;
      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay,
          )}
          style={{
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(", "),
            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
            "--index": index,
            "--color": color,
            marginTop: "1rem",
          }}
          ref={ref}
        >
          <Link
            to={`/leasing/lease/${commercialLeaseId}`}
            sx={{ width: "20.8rem" }}
          >
            <div
              className={classNames(
                styles.Item,
                dragging && styles.dragging,
                handle && styles.withHandle,
                dragOverlay && styles.dragOverlay,
                disabled && styles.disabled,
                color && styles.color,
              )}
              style={{ ...style, padding: "10px 13px" }}
              data-cypress="draggable-item"
              {...(!handle ? listeners : undefined)}
              {...props}
              tabIndex={!handle ? 0 : undefined}
            >
              <Stack direction="column" sx={{ width: "100%" }}>
                <Link
                  to={`/leasing/lease/${commercialLeaseId}`}
                  sx={{
                    fontWeight: 500,
                    ":hover": {
                      textDecoration: "underline",
                    },
                    width: "16.5rem",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {LeaseName}
                </Link>
                {property?.propertyId ? (
                  <Link
                    to={`/property/features/details?property=${property?.propertyId}`}
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                      },
                      fontSize: "12px",
                      width: "16.5rem",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {property?.PropertyName}
                  </Link>
                ) : (
                  <Box
                    sx={{
                      fontSize: "12px",
                      width: "16.5rem",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    No Property
                  </Box>
                )}
                <Box
                  sx={{
                    width: "16.5rem",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  Unit{units?.length > 1 && "s"}{" "}
                  {units?.length > 0
                    ? units?.map(({ UnitCode }) => UnitCode).join(", ")
                    : "None"}
                </Box>
              </Stack>
              <span className={styles.Actions}>
                {onRemove ? (
                  <Remove className={styles.Remove} onClick={onRemove} />
                ) : null}
                {handle && isBroker ? (
                  <Handle {...handleProps} {...listeners} />
                ) : null}
              </span>
            </div>
          </Link>
        </li>
      );
    },
  ),
);
