import { gql } from "@apollo/client";

export default gql`
  query GetAllUsersAdmin($q: String) {
    users: usersAdmin(q: $q) {
      userId
      email
      firstName
      lastName
      company
      title
      orgs {
        orgId
        displayName
        roleKey
        primary
        hasAllProperties
      }
      __typename
    }
  }
`;
