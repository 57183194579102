import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";

import { useLazyQuery } from "@apollo/client";
import { GET_UNITS } from "queries";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Loading from "components/shared/Loading";

function LeaseLocation({
  watch = _ => {},
  availableProperties,
  control,
  errors,
  setValue,
}) {
  const watchProperty = watch("propertyId");
  const watchUnit = watch("units");
  const [availableUnits, setAvailableUnits] = useState([]);
  const [previousProperty, setPreviousProperty] = useState();
  const [getAvailableUnits, { loading }] = useLazyQuery(GET_UNITS);
  useEffect(
    _ => {
      if (!watchProperty) {
        setAvailableUnits([]);
      } else {
        if (!previousProperty) {
          setPreviousProperty(watchProperty);
        } else if (previousProperty !== watchProperty) {
          setValue("units", []);
        }
        const fetchUnits = async _ => {
          const units = await getAvailableUnits({
            variables: { propertyId: watchProperty },
            notifyOnNetworkStatusChange: true,
          });

          setAvailableUnits(units?.data?.units || []);
        };
        fetchUnits();
      }
    },
    [watchProperty, getAvailableUnits],
  );

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="property-select">Property</InputLabel>
        <Controller
          name="propertyId"
          render={({ field }) => (
            <>
              <Select
                {...field}
                label={"Property"}
                id="property-select"
                disableUnderline
                placeholder="None"
                sx={{
                  ".MuiSelect-select": {
                    fontSize: "13px",
                  },
                }}
              >
                {availableProperties
                  .concat([{ propertyId: null, PropertyName: "None" }])
                  .map(property => (
                    <MenuItem
                      key={property.propertyId}
                      value={property.propertyId}
                    >
                      {property.PropertyName}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
          control={control}
        />
      </FormControl>
      {watchProperty && (
        <Controller
          control={control}
          name="units"
          render={({ field }) => (
            <FormControl>
              <InputLabel id="units">Unit(s)</InputLabel>
              <Select
                error={errors.orgId}
                labelId="units"
                label="Units"
                multiple
                renderValue={values => {
                  return (
                    <Box
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {availableUnits?.length > 0 &&
                        values.map(
                          (val, index) =>
                            `${
                              availableUnits.find(
                                ({ unitId }) => unitId === val,
                              )?.UnitCode
                            }${index < values.length - 1 ? ", " : ""}`,
                        )}
                    </Box>
                  );
                }}
                {...field}
              >
                {loading ? (
                  <Loading sx={{ mt: "4rem", height: "15rem" }} />
                ) : (
                  availableUnits.map(
                    ({ UnitCode, unitId, Floor, Building, SQFT, Status }) => (
                      <MenuItem
                        key={unitId}
                        value={unitId}
                        sx={{ fontSize: "1.3rem" }}
                      >
                        <Checkbox
                          sx={{ pl: 0, pr: "0.5rem" }}
                          checked={
                            watchUnit.find(unit => unit === unitId)
                              ? true
                              : false
                          }
                        />
                        <Box
                          sx={{
                            mr: "0.5rem",
                            fontWeight: 500,
                            width: "5rem",
                          }}
                        >
                          {UnitCode}
                        </Box>
                        {Building ? (
                          <Box sx={{ mr: "0.5rem", width: "5rem" }}>
                            Bldg {Building}
                          </Box>
                        ) : (
                          ""
                        )}
                        {Floor ? (
                          <Box sx={{ mr: "0.5rem", width: "5rem" }}>
                            Floor {Floor}
                          </Box>
                        ) : (
                          ""
                        )}
                        {SQFT ? (
                          <Box sx={{ width: "8rem", mr: "0.5rem" }}>
                            {SQFT.toLocaleString()} SQFT
                          </Box>
                        ) : (
                          ""
                        )}
                        <Box sx={{ width: "12rem" }}>
                          <b>{Status}</b>
                        </Box>
                      </MenuItem>
                    ),
                  )
                )}
              </Select>
            </FormControl>
          )}
        />
      )}
    </>
  );
}

export default LeaseLocation;
