import { IconButton, Stack, Typography } from "@mui/material";
import { getDisplayName } from "utils/formatters/user";
import dayjs from "dayjs";
import { ReadOnlyTextEditior } from "components/shared/TextEditor/useTextEditor";
import Divider from "@mui/material/Divider";
import { useState, useContext } from "react";
import useTextEditor from "components/shared/TextEditor/useTextEditor";
import EditIcon from "@mui/icons-material/Edit";
import { grey } from "@mui/material/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import { useMutation } from "@apollo/client";
import { UPDATE_COMMENT, REMOVE_COMMENT } from "queries";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import Tooltip from "@mui/material/Tooltip";
import CurrentUserContext from "contexts/CurrentUserContext";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
function ActionMenu({ onEdit, onDelete }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          color: "#C2CFE0",
          p: 0,
          height: "15px",
          ml: "auto",
          width: "15px",
          mt: "0.8rem",
        }}
        onClick={handleClick}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        dense={true}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: "10rem",
          },
        }}
      >
        <MenuItem
          onClick={_ => {
            onEdit();
            handleClose();
          }}
          dense={true}
        >
          <EditIcon sx={{ mr: "1rem", fontSize: "15px" }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={_ => {
            onDelete();
            handleClose();
          }}
          dense={true}
        >
          <DeleteIcon sx={{ mr: "1rem", fontSize: "15px" }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
function Comment({
  text,
  createdBy,
  createdAt,
  commercialLeaseId,
  leaseCommentId,
  refetch,
  updatedAt,
}) {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editText, setEditText] = useState(JSON.parse(text));
  const { TextEditor, clearEditor, editor } = useTextEditor({
    initialValue: editText,
    placeholder: "Leave a comment",
    onChange: value => {
      setEditText(value);
    },
    plaintext: true,
    sx: { height: "10rem" },
  });
  const [updateComment] = useMutation(UPDATE_COMMENT);
  const [deleteComment] = useMutation(REMOVE_COMMENT);
  const displayName = getDisplayName(createdBy);
  const createdDate = dayjs(createdAt);
  const updatedDate = dayjs(updatedAt);
  const { userId } = useContext(CurrentUserContext.Context);
  console.info("createdby", createdBy.userId, userId);
  const [displayText, setDisplayText] = useState(JSON.parse(text));
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function handleUpdate() {
    setSaving(true);
    await updateComment({
      variables: {
        updateCommentInput: {
          id: leaseCommentId,
          text: JSON.stringify(editText),
          entity: {
            type: "CommercialLease",
            id: commercialLeaseId,
          },
        },
      },
    });
    enqueueSnackbar(`Comment successfully updated`, {
      variant: "success",
    });
    setSaving(false);
    setDisplayText(editText);
    clearEditor(editText);

    setEditing(false);
  }
  async function handleDelete() {
    deleteComment({
      variables: {
        entity: { type: "CommercialLease", id: commercialLeaseId },
        removeCommentId: leaseCommentId,
      },
    });
    refetch();
    enqueueSnackbar(`Comment successfully deleted`, {
      variant: "success",
    });
  }

  return (
    <Stack direction="column" sx={{ width: "100%", mb: "1.5rem" }}>
      <Stack direction="row" sx={{ mb: "1rem" }}>
        <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: 500 }}>
          {displayName}
        </Typography>
        {/* <Dot sx={{ width: "9px", ml: "1rem", color: grey[500] }} /> */}
        <Tooltip
          title={dayjs(createdDate).format("MMMM DD[,] YYYY [at] HH:mm A")}
          arrow
          placement="top"
        >
          <Typography
            variant="p"
            sx={{
              color: grey[500],
              ml: "1rem",
              fontSize: "14px",
              mt: "3px",
              cursor: "pointer",
            }}
          >
            {dayjs().to(createdDate)}
          </Typography>
        </Tooltip>
        {updatedAt !== createdAt && (
          <Tooltip title={dayjs().to(updatedDate)} arrow placement="top">
            <Typography
              variant="p"
              sx={{
                color: grey[400],
                ml: "1rem",
                fontSize: "14px",
                mt: "3px",
                cursor: "pointer",
              }}
            >
              Edited
            </Typography>
          </Tooltip>
        )}
        {!editing ? (
          userId === createdBy.userId && (
            <ActionMenu
              onEdit={_ => setEditing(true)}
              onDelete={_ => setDeleting(true)}
            />
          )
        ) : (
          <IconButton
            sx={{
              color: "#C2CFE0",
              p: 0,
              mt: "0.5rem",
              ml: "auto",
              width: "21px",
              height: "21px",
            }}
            onClick={_ => {
              setEditing(false);
              // handleClose();
            }}
          >
            <CloseIcon sx={{ width: "21px", height: "21px" }} />
          </IconButton>
        )}
      </Stack>
      {editing ? (
        <>
          {TextEditor}
          <Stack direction="row" sx={{ mt: "1rem" }}>
            <LoadingButton
              variant="contained"
              sx={{ ml: "auto" }}
              loading={saving}
              onClick={handleUpdate}
            >
              Save
            </LoadingButton>
          </Stack>
        </>
      ) : (
        <ReadOnlyTextEditior value={displayText} />
      )}
      <ConfirmationDialog
        open={deleting}
        title={`Delete your comment?`}
        text={`Your comment will be deleted. This action cannot be undone.`}
        handleClose={e => {
          e?.preventDefault?.();
          setDeleting(false);
        }}
        handleConfirm={handleDelete}
      />
      <Divider sx={{ mt: "1rem" }} />
    </Stack>
  );
}
export default Comment;
