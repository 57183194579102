import { gql } from "@apollo/client";
import { PROPERTY_FIELDS } from "./fragments";

export default gql`
  ${PROPERTY_FIELDS}
  query GetProperty($id: String!) {
    property(id: $id) {
      ...PropertyFields
    }
  }
`;
