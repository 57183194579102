import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useQuery } from "@apollo/client";
import { GET_ME, GET_ALL_PORTFOLIOS } from "queries";
import Loading from "components/shared/Loading";
import Box from "@mui/material/Box";
import CustomThemeContext from "./CustomThemeContext";
import { PERMISSIONS, FEATURES } from "utils/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@mui/material";
const Context = createContext({ firstName: "", lastName: "" });
Context.displayName = "CurrentUserContext";

function continuePolling(error, count) {
  return error?.networkError?.statusCode === 418 && count <= 8000;
}
//Used to provide data about the currently logged in user
const Provider = ({ children }) => {
  const { data, loading, refetch: refetchMe, error } = useQuery(GET_ME);
  const { data: portfoliosData, refetch: refetchPortfolios } =
    useQuery(GET_ALL_PORTFOLIOS);
  const { logout } = useAuth0();
  const [failureCount, setFailureCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const { setTheme } = useContext(CustomThemeContext.Context);

  const refetch = useCallback(
    _ => {
      refetchMe();
      refetchPortfolios();
    },
    [refetchMe, refetchPortfolios],
  );

  useEffect(
    _ => {
      const orgs = data?.me?.orgs;
      if (orgs) {
        const primaryOrg = orgs.find(org => org.primary);
        if (primaryOrg?.theme) {
          const orgTheme = JSON.parse(primaryOrg.theme);
          setTheme(orgTheme, primaryOrg.logo);
        }
      }
    },
    [data, setTheme],
  );

  // On a users first sign in through a social provider we might not be done registering them in the app
  // Here we do a bit of polling to solve for that delay
  useEffect(
    _ => {
      if (continuePolling(error, failureCount)) {
        const timeoutId = setTimeout(_ => {
          setFailureCount(count => count + 2000);
          refetch();
        }, failureCount);
        return _ => {
          clearTimeout(timeoutId);
        };
      }
    },
    [error, failureCount, refetch],
  );

  if (error) {
    return (
      <Box sx={{ m: "5rem", textAlign: "center" }}>
        <Box
          sx={{
            fontSize: "2rem",
            mb: "2rem",
            color: "primary",
            mt: "10rem",
          }}
        >
          It looks like we're still working on setting up your user. This
          process can take up to 24 hours. Check back soon or{" "}
          <Link
            to="#"
            sx={{ cursor: "pointer" }}
            onClick={_ =>
              logout({ returnTo: `${window.location.origin}/login` })
            }
          >
            logout and try again
          </Link>
        </Box>
        <Loading sx={{ mt: "10rem" }} />
      </Box>
    );
    // return (
    //   <Box sx={{ m: "5rem", textAlign: "center" }}>
    //     {continuePolling(error, failureCount) ? (
    //       <>
    //         <Box
    //           sx={{
    //             fontSize: "2rem",
    //             mb: "2rem",
    //             color: "primary",
    //             mt: "10rem",
    //           }}
    //         >
    //           It looks like we're still working on setting up your user, this
    //           process can take up to 24 hours. Check back soon!
    //         </Box>
    //         <Loading sx={{ mt: "10rem" }} />
    //       </>
    //     ) : (
    //       <>
    //         <Box
    //           sx={{
    //             fontSize: "2rem",
    //             mb: "2rem",
    //             color: "primary",
    //             mt: "10rem",
    //           }}
    //         >
    //           We had trouble finding your information
    //         </Box>
    //         <Box>
    //           Please contact your administrator or reload this page if you
    //           believe this to be an error or{" "}
    //           <Link
    //             to="#"
    //             sx={{ cursor: "pointer" }}
    //             onClick={_ =>
    //               logout({ returnTo: `${window.location.origin}/login` })
    //             }
    //           >
    //             logout and try again
    //           </Link>
    //         </Box>
    //         <Box sx={{ mt: "2rem" }}>
    //           <Box
    //             sx={{ color: "blue", cursor: "pointer" }}
    //             onClick={_ => setShowError(prev => !prev)}
    //           >
    //             Click here for details
    //           </Box>
    //           <Box>{showError && error.message}</Box>
    //         </Box>
    //       </>
    //     )}
    //   </Box>
    // );
  }

  if (loading) return <Loading sx={{ mt: "25vh" }} />;

  return (
    <Context.Provider
      value={{
        ...(data && data.me),
        providerGroup: data ? data.providerGroup : {},
        properties: data ? data.properties : [],
        portfolios: portfoliosData ? portfoliosData.portfolios : [],
        refetch,
        can: function (allowedPermissions, requiredFeatures) {
          function checkPermissions() {
            if (!allowedPermissions || allowedPermissions.length === 0)
              return true;
            allowedPermissions.forEach(perm => {
              if (!PERMISSIONS[perm]) {
                throw new Error("permission not supported");
              }
            });
            if (data?.me) {
              const roleKey = data.me.orgs[0].roleKey;
              // Crex users have everything
              if (roleKey === PERMISSIONS.crex) {
                return true;
              }
              return allowedPermissions.find(perm => perm === roleKey);
            }
            return false;
          }
          function checkFeatures() {
            if (!requiredFeatures || requiredFeatures.length === 0) return true;

            requiredFeatures.forEach(feature => {
              if (!FEATURES[feature]) {
                throw new Error("feature not supported");
              }
            });
            if (data?.me) {
              return requiredFeatures.every(
                feature => data.me.orgs[0][feature],
              );
            }
            return false;
          }
          return checkPermissions() && checkFeatures();
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
const CurrentUserContext = { Context, Provider };
export default CurrentUserContext;
