import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Definitions from "./Definitions";

function Help() {
  return (
    <Stack className="Help" direction="column">
      <Typography
        variant="h3"
        sx={{ fontSize: "35px", mt: "5rem", textAlign: "center" }}
      >
        Help Center
      </Typography>

      <Typography sx={{ mr: "auto", ml: "auto", mt: "3rem" }}>
        Lumos spell not shedding light on your question?
      </Typography>
      <Typography sx={{ mr: "auto", ml: "auto", mt: "1rem" }}>
        Try
        <Link
          underline="none"
          sx={{ ml: "0.4rem", fontWeight: 600 }}
          target="_blank"
          href="https://crexchangedatalake.blob.core.windows.net/datalake/Documenation/CREx%20Software%20User%20Guide.pdf?sp=r&st=2022-10-19T00:34:03Z&se=2050-10-19T08:34:03Z&spr=https&sv=2021-06-08&sr=b&sig=4B%2FvIKWf3h73uSBwCBryx6gMQIqsKAHlJhU98DzjXO8%3D"
        >
          our user guide{" "}
        </Link>
        to see if that helps, or check the <b>glossary</b> below. Otherwise,
        reach out to us via our
        <Link
          underline="none"
          sx={{ ml: "0.4rem", fontWeight: 600 }}
          href="https://crexchange.atlassian.net/servicedesk/customer/portals"
          target="_blank"
        >
          support portal
        </Link>
        .
      </Typography>
      <Definitions />
    </Stack>
  );
}

export default Help;
