import { useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { NavLink as Link, useLocation } from "react-router-dom";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Box from "@mui/material/Box";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import CurrentUserContext from "contexts/CurrentUserContext";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { createSvgIcon } from "@mui/material/utils";
import { PERMISSIONS, FEATURES } from "utils/constants";
import FolderIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import StoreIcon from "@mui/icons-material/Store";

const NavLink = styled(Link)`
  text-decoration: none;
`;
const mixin = props => `
  margin-right: 4.8rem;
  color: ${props.theme.palette.neutral.main};
  display: flex;
  padding-bottom: 1.5rem;
  height: 100%;
`;

const Active = styled(Box)`
  ${props => mixin(props)}
  ${props => {
    const color = props.theme.palette.primary.main;
    return `
      color: ${color};
      border-bottom: 4px solid ${color};
    `;
  }}
  padding-bottom: 1.1rem;
`;
const Inactive = styled(Box)`
  ${props => mixin(props)}
  color: ${props => props.theme.palette.neutral.main};
  &:hover {
    color: ${props => props.theme.palette.primary.light};
    svg {
      color: ${props => props.theme.palette.primary.light};
    }
  }
`;
const List = styled("ul")`
  display: flex;
  list-style: none;
  align-items: flex-end;
  margin: 0;
`;
const OwnerIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M6 42v-3.8l3-3V42Zm8.25 0V30.2l3-3V42Zm8.25 0V27.2l3 3.05V42Zm8.25 0V30.25l3-3V42ZM39 42V22.2l3-3V42ZM6 30.2v-4.25l14-13.9 8 8L42 6v4.25L28 24.3l-8-8Z" />
  </svg>,

  "Owner Portal",
);
function LinkContent({ isActive, section, Icon }) {
  const theme = useTheme();
  const Container = isActive ? Active : Inactive;

  return (
    <Container>
      <Icon
        sx={{
          marginRight: "0.6rem",
          color: isActive
            ? theme.palette.primary.main
            : theme.palette.neutral.main,
        }}
      />
      <Box
        sx={{
          position: "relative",
          top: 1,
        }}
      >
        {section}
      </Box>
    </Container>
  );
}
function Nav() {
  let location = useLocation();
  const { can } = useContext(CurrentUserContext.Context);
  const notOwner = can([PERMISSIONS.admin, PERMISSIONS.manager]);
  return (
    <nav>
      <List data-cy="globalNavList">
        {can(
          [PERMISSIONS.admin, PERMISSIONS.manager],
          [FEATURES.featureReporting],
        ) && (
          <li>
            <NavLink to="property">
              {({ isActive }) => (
                <LinkContent
                  isActive={isActive}
                  section="Properties"
                  Icon={LocationCityIcon}
                />
              )}
            </NavLink>
          </li>
        )}
        {can([], [FEATURES.featureReporting]) && (
          <li>
            <NavLink to="ownerportal">
              {({ isActive }) => (
                <LinkContent
                  isActive={isActive}
                  section={"Reporting"}
                  Icon={OwnerIcon}
                />
              )}
            </NavLink>
          </li>
        )}
        {can([], [FEATURES.featureDataProvider]) && (
          <li>
            <NavLink to="fido">
              {({ isActive }) => (
                <LinkContent
                  isActive={isActive}
                  section={"FIDO"}
                  Icon={FolderIcon}
                />
              )}
            </NavLink>
          </li>
        )}
        {can([], [FEATURES.featureLeasing]) && (
          <li>
            <NavLink to="leasing">
              {({ isActive }) => (
                <LinkContent
                  isActive={isActive}
                  section={"Leasing"}
                  Icon={StoreIcon}
                />
              )}
            </NavLink>
          </li>
        )}
        {can([PERMISSIONS.admin], [FEATURES.featureLeasing]) && (
          <li>
            <NavLink to="property">
              {({ isActive }) => (
                <LinkContent
                  isActive={isActive}
                  section="Properties"
                  Icon={LocationCityIcon}
                />
              )}
            </NavLink>
          </li>
        )}
        {can(
          [PERMISSIONS.crex, PERMISSIONS.admin],
          [FEATURES.featureDataProvider],
        ) && (
          <li>
            <NavLink to="fidostatus">
              {({ isActive }) => (
                <LinkContent
                  isActive={isActive}
                  section={"Status"}
                  Icon={TroubleshootIcon}
                />
              )}
            </NavLink>
          </li>
        )}
        {(can([PERMISSIONS.admin], [FEATURES.featureReporting]) ||
          can([PERMISSIONS.admin], [FEATURES.featureLeasing]) ||
          can([PERMISSIONS.crex])) && (
          <li>
            <NavLink to="admin">
              {({ isActive }) => {
                const active =
                  isActive || location.pathname.match(/\/profile\//);
                return (
                  <LinkContent
                    isActive={active}
                    section="Admin"
                    Icon={AllInclusiveIcon}
                  />
                );
              }}
            </NavLink>
          </li>
        )}
      </List>
    </nav>
  );
}

export default Nav;
