import { gql } from "@apollo/client";
import { PROPERTY_FIELDS } from "./fragments";
export default gql`
  ${PROPERTY_FIELDS}
  query GetProperties($q: String) {
    properties(q: $q) {
      ...PropertyFields
    }
  }
`;
