import { gql } from "@apollo/client";
import { PROPERTY_FIELDS } from "./fragments";

export default gql`
  ${PROPERTY_FIELDS}

  query GetPortfolio($id: String!) {
    portfolio(id: $id) {
      portfolioId
      displayName
      properties {
        ...PropertyFields
      }
      __typename
    }
  }
`;
