import { createContext, useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { GET_REPORTS } from "queries";
import { useSnackbar } from "notistack";
import useInterval from "hooks/useInterval";

const Context = createContext({});
Context.displayName = "ReportsContext";
function filteredReports(reports) {
  // handles report overrides, only allows a single instance of any given reportCode
  const reducedReports = reports.reduce((prev, curr) => {
    if (prev[curr.reportCode]) {
      // when there is already a value, take the one with an orgId
      // this is how we override which report shows up
      if (curr.orgId) {
        prev[curr.reportCode] = curr;
      }
    } else {
      prev[curr.reportCode] = curr;
    }
    return prev;
  }, {});
  return Object.values(reducedReports);
}
//Used to get the list of reports a user has access to and a token valid for those reports and this user
const Provider = ({ children }) => {
  // get the reports list and accessToken from the BE
  const { data, error, refetch, loading } = useQuery(GET_REPORTS);
  const [sections, setSections] = useState({});
  const [reports, setReports] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const checkAndRefreshToken = useCallback(
    _ => {
      const tokenExp = data?.reports?.embeds?.[0]?.token?.expiration;

      // Get the current time
      const currentTime = Date.now();
      const expiration = Date.parse(tokenExp);

      // Time until token expiration in milliseconds
      const timeUntilExpiration = expiration - currentTime;
      // when the token is whithin this long from expiring
      const timeToUpdate = 10 * 60 * 1000;

      // Update the token if it is about to expired
      if (timeUntilExpiration <= timeToUpdate) {
        refetch();
      }
    },
    [data, refetch],
  );

  useInterval(checkAndRefreshToken, 3 * 60 * 1000);

  useEffect(
    _ => {
      const reports = data?.reports?.reports
        ? filteredReports(data.reports.reports)
        : [];
      // setup map of the sections by looking through all reports. Example:
      // {financials: ["_reportCode1", "..."]}
      if (reports && reports.length > 0) {
        const newSections = reports.reduce((acc, cur) => {
          const currentSection = cur.section.split(".")[0];
          if (acc[currentSection]) {
            acc[currentSection].push(cur);
          } else {
            acc[currentSection] = [cur];
          }
          return acc;
        }, {});

        // sort each one
        Object.keys(newSections).forEach(sect => {
          const reps = newSections[sect];
          newSections[sect] = reps.sort((a, b) => {
            const aIndex = Number(a.section.split(".")[1]);
            const bIndex = Number(b.section.split(".")[1]);
            return aIndex - bIndex;
          });
        });

        setSections(newSections);
        setReports(reports);
      }
    },
    [data, setReports],
  );

  useEffect(
    _ => {
      if (error) {
        // add action to an individual snackbar
        // const action = snackbarId => (
        //   <>
        //     <Link
        //       to="#"
        //       sx={{ cursor: "pointer" }}
        //       underline="none"
        //       onClick={_ => closeSnackbar(snackbarId)}
        //     >
        //       Dismiss
        //     </Link>
        //   </>
        // );
        console.error("Error getting reports", error);
        // enqueueSnackbar(
        //   `We're having some difficulty finding your reports, please reload this page or contact an admin if you experience any issues`,
        //   { variant: "error", persist: true, action },
        // );
      }
    },
    [error, enqueueSnackbar, closeSnackbar],
  );

  return (
    <Context.Provider
      value={{
        reports,
        embeds: data?.reports?.embeds || [],
        sections,
        getReportByCode: code => reports?.find(rep => rep.reportCode === code),
        loading,
      }}
    >
      {children}
    </Context.Provider>
  );
};
const ReportsContext = { Context, Provider };
export default ReportsContext;
