import { gql } from "@apollo/client";

export default gql`
  mutation CreateEmailSubscription(
    $createEmailSubscriptionInput: CreateEmailSubscriptionInput!
  ) {
    createEmailSubscription(
      createEmailSubscriptionInput: $createEmailSubscriptionInput
    ) {
      emailSubscriptionId
    }
  }
`;
