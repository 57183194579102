import { gql } from "@apollo/client";

export default gql`
  query BrokerGroups {
    brokerGroups {
      displayName
      orgId
      brokerGroupId
      createdAt

      users {
        firstName
        lastName
        userId
      }
      properties {
        propertyId
        PropertyName
        PropertyKey
      }
    }
  }
`;
