import { gql } from "@apollo/client";

export default gql`
  query GetCommercialLease($commercialLeaseId: Int!) {
    commercialLease(id: $commercialLeaseId) {
      LeaseFrom
      LeaseName
      LeaseTo
      LsType
      MoveIn
      NAICSCode
      SecurityDeposit
      SignDate
      Stage
      TenantDbaName
      commercialLeaseId
      notes
      property {
        PropertyName
        PropertyKey
        propertyId
        County
        Class
        City
        Address
        MSA
        NumberOfUnits
        PropertyManager
        SourceKey
        State
        Submarket
        Type
        YearBuilt
        TotalSQFT
        Zip
        acreage
        amenities
        coveredParking
        leasingStage
        orgId
        reservedParking
        subtype
        synced
        totalParking
        website
      }
      GrossRentAnnualPerArea
      MiscellaneousPerArea
      RecoveryPerArea
      units {
        Building
        Floor
        SQFT
        Unitkey
        UnitCode
        unitId
        FloorPlan
        Status
      }
      createdBy {
        userId
        firstName
        lastName
      }
      updatedBy {
        userId
        firstName
        lastName
      }
      createdAt
      updatedAt
      assignee {
        userId
        firstName
        lastName
      }
      brokerGroup {
        brokerGroupId
        displayName
      }
    }
  }
`;
