import { gql } from "@apollo/client";
import { USER_FIELDS, USER_PROPERTY_FIELDS } from "./fragments";
export default gql`
  ${USER_FIELDS}
  ${USER_PROPERTY_FIELDS}
  query GetUserWithProperties($userId: String!) {
    user(userId: $userId) {
      ...UserFields
      title
      company
      properties {
        ...UserPropertyFields
      }
      brokerGroup {
        brokerGroupId
        displayName
      }
    }
  }
`;
