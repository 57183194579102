import { gql } from "@apollo/client";

export default gql`
  mutation UpdateEmailSubscription(
    $updateEmailSubscriptionInput: UpdateEmailSubscriptionInput!
  ) {
    updateEmailSubscription(
      updateEmailSubscriptionInput: $updateEmailSubscriptionInput
    ) {
      emailSubscriptionId
    }
  }
`;
