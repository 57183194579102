import { gql } from "@apollo/client";
export default gql`
  query GetPropertyUsers($propertyId: Int!) {
    getPropertyUsers(propertyId: $propertyId) {
      userId
      email
      firstName
      lastName
      company
      title
      orgs {
        orgId
        roleKey
        hasAllProperties
      }
      __typename
    }
  }
`;
