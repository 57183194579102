import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Ted from "images/Teddy.png";
import styled from "@emotion/styled";
const Teddy = styled("img")`
  width: 270px;
  margin: 0 auto;
`;
function ComingSoon() {
  return (
    <Card
      sx={{
        p: "5rem",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Teddy src={Ted} alt="Crex" data-cy="homeLogo" />
      <Box sx={{ textAlign: "center", fontSize: "25px", mt: "3rem" }}>
        Coming soon!
      </Box>
    </Card>
  );
}

export default ComingSoon;
