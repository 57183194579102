import { gql } from "@apollo/client";

export default gql`
  query EmailSubscriptions($filters: FiltersEmailSubscriptionInput) {
    emailSubscriptions(filters: $filters) {
      emailSubscriptionId
      displayName
      cadence
      occurrence
      portfolioId
      reportId
      users {
        email
        firstName
        lastName
        userId
      }
    }
  }
`;
