import { Routes, Route } from "react-router-dom";
import Chrome from "./Chrome";
import CssBaseline from "@mui/material/CssBaseline";
import CurrentUserContext from "contexts/CurrentUserContext";
import ReportsContext from "contexts/ReportsContext";
import SearchContext from "contexts/SearchContext";
import Login from "components/Login";
import RequireAuth from "auth/RequireAuth";
import HomeBeforeRedirect from "components/Home/HomeBeforeRedirect";
import { useQueryParams, StringParam, withDefault } from "use-query-params";
import TagsContext from "contexts/TagsContext";
import SecuredRoutes from "./SecuredRoutes";

import "filepond/dist/filepond.min.css";

function Main() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    en: withDefault(StringParam, "properties"),
  });

  return (
    <>
      <CssBaseline />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="" element={<HomeBeforeRedirect />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <ReportsContext.Provider>
                <CurrentUserContext.Provider>
                  <SearchContext.Provider query={query} setQuery={setQuery}>
                    <TagsContext.Provider>
                      <Chrome>
                        <SecuredRoutes />
                      </Chrome>
                    </TagsContext.Provider>
                  </SearchContext.Provider>
                </CurrentUserContext.Provider>
              </ReportsContext.Provider>
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
}

export default Main;
