import { gql } from "@apollo/client";

export const PROPERTY_FIELDS = gql`
  fragment PropertyFields on Property {
    propertyId
    PropertyName
    Type
    subtype
    leasingStage
    Class
    TotalSQFT
    NumberOfUnits
    acreage
    website
    Address
    City
    State
    Zip
    Submarket
    MSA
    County
    totalParking
    reservedParking
    coveredParking
    amenities
    PropertyManager
    YearBuilt
    PropertyKey
    SourceKey
    orgId
    users {
      userId
      email
      firstName
      lastName
      company
      title
      orgs {
        orgId
        roleKey
        hasAllProperties
      }
      __typename
    }
    __typename
  }
`;
export const MIN_PROPERTY_FIELDS = gql`
  fragment MinPropertyFields on Property {
    propertyId
    PropertyName
    Type
    subtype
    leasingStage
    Class
    TotalSQFT
    NumberOfUnits
    acreage
    website
    Address
    City
    State
    Zip
    Submarket
    MSA
    County
    totalParking
    reservedParking
    coveredParking
    amenities
    PropertyManager
    YearBuilt
    PropertyKey
    SourceKey
    __typename
  }
`;

export const PORTFOLIO_FIELDS = gql`
  ${MIN_PROPERTY_FIELDS}
  fragment PortfolioFields on Portfolio {
    portfolioId
    displayName
    __typename
    children {
      ...MinPropertyFields
      ... on Portfolio {
        portfolioId
        displayName
        __typename
        children {
          ...MinPropertyFields
          ... on Portfolio {
            portfolioId
            displayName
            __typename
            children {
              ...MinPropertyFields
              ... on Portfolio {
                portfolioId
                displayName
                __typename
                children {
                  ...MinPropertyFields
                  ... on Portfolio {
                    portfolioId
                    displayName
                    __typename
                    children {
                      ...MinPropertyFields
                      ... on Portfolio {
                        portfolioId
                        displayName
                        __typename
                        children {
                          ...MinPropertyFields
                          ... on Portfolio {
                            portfolioId
                            displayName
                            __typename
                            children {
                              ...MinPropertyFields
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    email
    userId
    firstName
    lastName
    orgs {
      orgId
      roleKey
      roleId
      hasAllProperties
    }
  }
`;
export const USER_PROPERTY_FIELDS = gql`
  fragment UserPropertyFields on UserProperty {
    propertyId
    PropertyName
    Type
    subtype
    leasingStage
    Class
    TotalSQFT
    NumberOfUnits
    acreage
    website
    Address
    City
    State
    Zip
    Submarket
    MSA
    County
    totalParking
    reservedParking
    coveredParking
    amenities
    PropertyManager
    YearBuilt
    PropertyKey
    SourceKey
  }
`;
