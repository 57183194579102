import { createContext, useCallback, useState } from "react";
import { createTheme } from "@mui/material/styles";

const Context = createContext({});
Context.displayName = "CustomThemeContext";

const Provider = ({ children, setTheme, defaultTheme }) => {
  const [logo, setLogo] = useState("");
  const _setTheme = useCallback(
    (palette, customLogo) => {
      if (customLogo) {
        setLogo(customLogo);
      }
      setTheme(
        createTheme({
          ...defaultTheme,
          palette: { ...defaultTheme.palette, ...palette },
        }),
      );
    },
    [defaultTheme, setTheme],
  );
  return (
    <Context.Provider
      value={{
        setTheme: _setTheme,
        logo,
      }}
    >
      {children}
    </Context.Provider>
  );
};
const CustomThemeContext = { Context, Provider };
export default CustomThemeContext;
