import { gql } from "@apollo/client";

export default gql`
  query Doctags {
    doctags {
      orgId
      displayName
      doctagId
    }
  }
`;
