import { useEffect, useState, forwardRef } from "react";
import debounce from "lodash/debounce";

const useWindow = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);
    return _ => window.removeEventListener("resize", debouncedHandleResize);
  });
  return { height, width, chromeHeight: 64 };
};
export default useWindow;
