import { createContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_DOCTAGS } from "queries";

const Context = createContext({});
Context.displayName = "TagsContext";

const Provider = ({ children }) => {
  const { data, loading, refetch } = useQuery(GET_DOCTAGS);
  const tags = data?.doctags;

  const [tagColors, setTagColors] = useState({});

  return (
    <Context.Provider
      value={{
        tags,
        loading,
        refetch,
        tagColors,
        setTagColors,
      }}
    >
      {children}
    </Context.Provider>
  );
};
const TagsContext = { Context, Provider };
export default TagsContext;
