import { useAuth0 } from "@auth0/auth0-react";
import { useQueryParams, StringParam } from "use-query-params";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const HomeBeforeRedirect = () => {
  const { logout } = useAuth0();
  let navigate = useNavigate();
  const [query] = useQueryParams({
    error: StringParam,
  });

  useEffect(
    _ => {
      if (query.error) {
        console.error("Error in query:", query.error);
        logout({ returnTo: `${window.location.origin}/login` });
        return;
      } else {
        navigate("/home");
      }
    },
    [query.error, logout, navigate],
  );

  return null;
};

export default HomeBeforeRedirect;
