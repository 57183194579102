import { gql } from "@apollo/client";

export default gql`
  query AvailableProviderMembers($providerGroupId: Int) {
    availableProviderMembers(providerGroupId: $providerGroupId) {
      userId
      email
      firstName
      lastName
      company
      title
      orgs {
        orgId
        displayName
        roleKey
        primary
        hasAllProperties
      }
    }
  }
`;
