import { useQuery, useMutation } from "@apollo/client";
import { GET_LEASE_COMMENTS, CREATE_COMMENT } from "queries";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import useTextEditor from "components/shared/TextEditor/useTextEditor";
import Loading from "components/shared/Loading";
import Comment from "./Comment";
import LoadingButton from "@mui/lab/LoadingButton";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function LeaseComments({ commercialLeaseId }) {
  const { data, refetch, loading } = useQuery(GET_LEASE_COMMENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { entity: { id: commercialLeaseId, type: "CommercialLease" } },
  });
  const [createComment] = useMutation(CREATE_COMMENT);
  const [text, setText] = useState([]);
  const [saving, setSaving] = useState(false);
  const { TextEditor, clearEditor } = useTextEditor({
    placeholder: "Leave a comment",
    onChange: value => {
      setText(value);
    },
    plaintext: true,
  });
  async function handleComment() {
    setSaving(true);
    await createComment({
      variables: {
        createCommentInput: {
          text: JSON.stringify(text),
          entity: {
            type: "CommercialLease",
            id: commercialLeaseId,
          },
        },
      },
    });

    setSaving(false);
    refetch();
    setText([]);
    clearEditor();
  }
  return (
    <Box>
      <Paper
        elevation={2}
        sx={{
          p: "3rem",
          mt: "1rem",
          borderRadius: "8px",
          ml: "2rem",
          width: "50rem",
          mb: "1rem",
          mr: "2rem",
        }}
      >
        <Stack direction="column" sx={{ width: "100%" }}>
          <Typography variant="h4" sx={{ fontSize: "20px", mb: "2rem" }}>
            Comments
          </Typography>
          {loading ? (
            <Loading sx={{ mt: "3rem", mb: "3rem" }} />
          ) : data?.comments?.length > 0 ? (
            data.comments.map(
              comment =>
                comment?.text && (
                  <Comment
                    key={comment.leaseCommentId}
                    commercialLeaseId={commercialLeaseId}
                    refetch={refetch}
                    {...comment}
                  />
                ),
            )
          ) : (
            <Box sx={{ ml: "auto", mr: "auto", mt: "3rem", mb: "3rem" }}>
              Be the first to comment!
            </Box>
          )}
          {TextEditor}
          <Stack direction="row" sx={{ mt: "1rem" }}>
            <LoadingButton
              variant="contained"
              sx={{ ml: "auto" }}
              disabled={loading}
              loading={saving}
              onClick={handleComment}
            >
              Send
            </LoadingButton>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}

export default LeaseComments;
