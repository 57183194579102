import { gql } from "@apollo/client";

export default gql`
  mutation UpdateCommercialLeaseStage(
    $commercialLeaseId: Int!
    $Stage: String!
  ) {
    updateCommercialLeaseStage(
      commercialLeaseId: $commercialLeaseId
      Stage: $Stage
    ) {
      commercialLeaseId
    }
  }
`;
