import React, { forwardRef } from "react";
import classNames from "classnames";
import { Button } from "@mui/material";
import styles from "./Action.module.css";

export const Action = forwardRef(
  ({ active, className, cursor, style, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        {...props}
        className={classNames(styles.Action, className)}
        tabIndex={0}
        style={{
          ...style,
          cursor,
          "--fill": active?.fill,
          "--background": active?.background,
        }}
        sx={{
          flex: "0 0 auto",
          margin: "auto",
          height: "100%",
          overflow: "visible",
          fill: "#919eab",
          mt: "1.9rem",
          minWidth: "30px",
          pr: 0,
          pl: 0,
        }}
      />
    );
  },
);
